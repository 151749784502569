import { isClientSide } from '@naturalcycles/js-lib'
import React from 'react'

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = React.useState<{
    width: number | null
    height: number | null
  }>({
    width: null,
    height: null,
  })

  React.useEffect(() => {
    if (!isClientSide()) {
      setWindowDimensions({ width: null, height: null })
    } else {
      setWindowDimensions({ width: window.innerWidth, height: window.innerHeight })
    }

    const handleResize = () => {
      setWindowDimensions({ width: window.innerWidth, height: window.innerHeight })
    }

    if (!isClientSide()) return
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}
