import { _stringEnumKey } from '@naturalcycles/js-lib'
import BurgerIcon from '@src/components/burger-icon/BurgerIcon.component'
import { ClientOnly } from '@src/components/client-only/ClientOnly.component'
import { DesktopMenu } from '@src/components/desktop-menu/DesktopMenu.component'
import { HeaderThemes } from '@src/components/header/Header.component'
import Logo from '@src/components/logo/Logo.component'
import RegionIndicator from '@src/components/region-indicator/RegionIndicator.component'
import RegionPicker from '@src/components/region-picker/RegionPicker.component'
import { useAppDispatch } from '@src/hooks/useAppDispatch'
import { useAppSelector } from '@src/hooks/useAppSelector'
import { Breakpoints, useBreakpoint } from '@src/hooks/useBreakpoint'
import { RegionSlug, ShippingLocations, shippingRegionPages } from '@src/shop/cnst/shopify.cnst'
import ShopifyCartIcon from '@src/shop/components/cart-icon/CartIcon.component'
import {
  selectExpectedLocation,
  selectShopifyShippingLocation,
  setExpectedLocationByCountry,
  setShippingLocation,
  setShippingLocationByCountry,
} from '@src/shop/store/shopify.slice'
import { selectGeolocationCountry } from '@src/store/geolocation/geolocation.slice'
import React from 'react'
import styles from './HeaderShop.module.scss'

interface HeaderShopProps {}

const HeaderShop = ({}: HeaderShopProps): React.ReactElement => {
  const isMobile = useBreakpoint().width < Breakpoints.bpLarge
  const shippingLocation = useAppSelector(selectShopifyShippingLocation)
  const country = useAppSelector(selectGeolocationCountry)

  const urlFragmentToShippingLocation = (url: string) => {
    const parsed = url.split('?')[0]!
    const parts = parsed.split('/')
    const shopIndex = parts.indexOf('shop')
    const locationIndex = shopIndex + 1
    const urlLocationPart = parts[locationIndex]
    return shippingRegionPages.find(page => page.slug === urlLocationPart)?.shopifyTag || null
  }

  const dispatch = useAppDispatch()

  const getHomeUrl = (shippingLocation?: ShippingLocations) => {
    if (!shippingLocation) return `/shop/row${window.location.search}`
    const regionKeyOrBlocked = _stringEnumKey(ShippingLocations, shippingLocation)
    const slugSuffix = regionKeyOrBlocked === 'Blocked' ? 'row' : RegionSlug[regionKeyOrBlocked]
    return `/shop/${slugSuffix}${window.location.search}`
  }

  const [shopHomeUrl, setShopHomeUrl] = React.useState(getHomeUrl(shippingLocation))

  const selectRegion = (shippingRegion?: ShippingLocations) => {
    if (!shippingRegion) {
      dispatch(setShippingLocationByCountry(country))
    } else {
      dispatch(setShippingLocation(shippingRegion))
    }
  }

  const expectedLocation = useAppSelector(selectExpectedLocation)

  const [showRegionIndicator, setShowRegionIndicator] = React.useState(false)

  const urlLocation = urlFragmentToShippingLocation(window.location.pathname)
  const useRegion = urlLocation || shippingLocation

  React.useEffect(() => {
    if (!expectedLocation) {
      dispatch(setExpectedLocationByCountry(country))
    }
  }, [country, expectedLocation])

  React.useEffect(() => {
    setShowRegionIndicator(useRegion !== expectedLocation)
  }, [useRegion, expectedLocation])

  React.useEffect(() => {
    setShopHomeUrl(getHomeUrl(useRegion))
    selectRegion(useRegion)
  }, [useRegion])

  return (
    <div className={styles.HeaderShop}>
      <ClientOnly>{showRegionIndicator && <RegionIndicator />}</ClientOnly>
      <div className={styles.HeaderShop__layout}>
        <div className={styles.HeaderShop__burger}>
          <BurgerIcon color="#72035D" />
        </div>

        <div className={styles.HeaderShop__logo}>
          <ClientOnly>
            <Logo href={shopHomeUrl} color="#72035D" uid="header-shop-logo" />
          </ClientOnly>
        </div>

        <div className={styles.HeaderShop__menu}>
          <DesktopMenu theme={HeaderThemes.ShopPurple} />
        </div>

        {isMobile ? (
          <></>
        ) : (
          <div className={styles.HeaderShop__regionPicker}>
            <RegionPicker />
          </div>
        )}

        <div className={styles.HeaderShop__cart}>
          <ShopifyCartIcon />
        </div>
      </div>
    </div>
  )
}

export default HeaderShop
