import { T2_PRESALE_COUNTRIES } from '@naturalcycles/shared'
import { GeolocationCountries } from '@src/store/geolocation/geolocation.slice'
import React from 'react'
import { MenuDropdown } from './menu-dropdown/MenuDropdown.component'
import { MenuLibrary } from './menu-library/MenuLibrary.component'
import { cyclematterLibraryData, cyclemattersDesktopData, whatIsIncluded } from './MenuData'
import { MenuEntry } from './Typings'

export const menu: MenuEntry[] = [
  {
    type: 'link',
    id: '1',
    text: 'How it works',
    href: '/how-does-natural-cycles-work',
  },
  {
    type: 'submenu',
    id: '2',
    text: "What's included?",
    submenu: <MenuDropdown {...whatIsIncluded} />,
  },
  {
    type: 'link',
    id: '3',
    text: 'Ways to measure',
    href: '/devices/eu',
    region: [...T2_PRESALE_COUNTRIES, GeolocationCountries.US],
  },
  {
    type: 'link',
    id: '4',
    text: 'Reviews',
    href: '/reviews',
  },
  {
    type: 'link',
    id: '5',
    text: 'FAQs',
    href: '/faqs',
  },
  {
    type: 'submenu',
    id: '6',
    text: 'Cyclematter Library',
    submenu: <MenuLibrary {...cyclematterLibraryData} desktopInfo={cyclemattersDesktopData} />,
  },
  {
    type: 'minorMenu',
    id: '7',
    text: 'Manage account',
    href: 'https://signup.naturalcycles.com/en-US/account/login',
  },
  {
    type: 'minorMenu',
    id: '8',
    text: 'NC° Shop',
    href: '/shop',
    display: 'mobile',
  },
]
