import { useAppSelector } from '@src/hooks/useAppSelector'
import { ShippingCurrency } from '@src/shop/cnst/shopify.cnst'
import { selectShopifyCurrency, selectShopifyShippingLocation } from '@src/shop/store/shopify.slice'
import React from 'react'
import styles from './BannerShop.module.scss'

interface BannerShopProps {}

const BannerShop = ({}: BannerShopProps): React.ReactElement => {
  const currentCurrency = useAppSelector(selectShopifyCurrency)
  const shippingLocation = useAppSelector(selectShopifyShippingLocation)
  const [currencyCode, setCurrencyCode] = React.useState<ShippingCurrency>(ShippingCurrency.US)

  const currencyAmounts = {
    [ShippingCurrency.SE]: 400,
    [ShippingCurrency.EU]: 40,
    [ShippingCurrency.GB]: 30,
    [ShippingCurrency.US]: 40,
  }
  const amount = currencyAmounts[currencyCode] || 40

  const formattedAmount = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyCode,
    maximumSignificantDigits: 3,
  }).format(amount)

  React.useEffect(() => {
    setCurrencyCode(currentCurrency)
  }, [shippingLocation])

  return (
    <div className={styles.BannerShop}>
      <p className={styles.BannerShop__text}>Free shipping on orders over {formattedAmount}</p>
    </div>
  )
}

export default BannerShop
