import LanguageContext from '@src/context/language.context'
import { useAppSelector } from '@src/hooks/useAppSelector'
import { selectGeolocationCountry } from '@src/store/geolocation/geolocation.slice'
import { menu } from '@src/test280/Menu'
import React from 'react'
import styles from './DesktopMenu.module.scss'

export const DesktopMenu = (): React.ReactElement => {
  const country = useAppSelector(selectGeolocationCountry)
  const { locale } = React.useContext(LanguageContext)

  // Not all menu items are available in all countries and locales
  // TOD0: if test passes move this to helpers
  const filteredMenuItems = menu.filter(
    item =>
      (!item.region || item.region.includes(country)) &&
      (!item.locale || item.locale.includes(locale)),
  )

  const mainMenuItems = filteredMenuItems.filter(item => item.type !== 'minorMenu')

  return (
    <ul uid="site-desktop-menu" className={styles.DesktopMenu}>
      {mainMenuItems.map(item =>
        item.type === 'link' ? (
          <li key={item.href} className={styles.DesktopMenu__item}>
            <a href={item.href}>{item.text}</a>
          </li>
        ) : (
          <React.Fragment key={item.id}>{item.submenu}</React.Fragment>
        ),
      )}
    </ul>
  )
}
