import { _clamp } from '@naturalcycles/js-lib'
import React from 'react'
import styles from './ProductQuantityControl.module.scss'

export interface ProductQuantityControlProps {
  quantity: number
  minimum: number
  maximum: number
  productName?: string
  onChange?: (quantity: number) => void
}

const defaultOnChange = (_quantity: number) => undefined

const ProductQuantityControl = ({
  quantity = 1,
  minimum,
  maximum,
  productName,
  onChange = defaultOnChange,
}: ProductQuantityControlProps) => {
  return (
    <div className={styles.ProductQuantityControl}>
      <button
        className={styles.ProductQuantityControl__decrement}
        onClick={() => onChange(_clamp(quantity - 1, minimum, maximum))}
        aria-label={`decrease quantity of ${productName} by one`}
      >
        -
      </button>
      <span
        className={styles.ProductQuantityControl__quantity}
        aria-live="polite"
        aria-label={`items of ${productName} in cart`}
        aria-labelledby={`quantity-${productName}`}
        role="spinbutton"
        tabIndex={0}
        aria-valuemax={maximum}
        aria-valuemin={minimum}
        aria-valuenow={quantity}
      >
        {quantity}
      </span>
      <button
        className={styles.ProductQuantityControl__increment}
        onClick={() => onChange(_clamp(quantity + 1, minimum, maximum))}
        aria-label={`increase quantity of ${productName} by one`}
      >
        +
      </button>
    </div>
  )
}
export default ProductQuantityControl
