import { useAppDispatch } from '@src/hooks/useAppDispatch'
import { useAppSelector } from '@src/hooks/useAppSelector'
import AddToCartButton from '@src/shop/components/add-to-cart-button/AddToCartButton.component'
import { ProductFrequencyControl } from '@src/shop/components/product-frequency-control/ProductFrequencyControl.component'
import ProductQuantityControl from '@src/shop/components/product-quantity-control/ProductQuantityControl.component'
import { shopifyService } from '@src/shop/srv/shopify.service'
import {
  AddToCartSources,
  PersistedSellingPlan,
  ShopifyPrice,
  ShopifyProduct,
} from '@src/shop/store/shopify.model'
import { changeIsSubscription, changeLineItemSellingPlan } from '@src/shop/store/shopify.slice'
import classNames from 'classnames'
import { graphql } from 'gatsby'
import React from 'react'
import styles from './ProductOptions.module.scss'

export interface ProductOptionsProps {
  product: ShopifyProduct
  price: ShopifyPrice['price']
  source: ProductOptionSource
}

export const ProductOptions = ({
  product,
  price,
  source,
}: ProductOptionsProps): React.ReactElement => {
  const isCart = [ProductOptionSource.MiniCart, ProductOptionSource.Cart].includes(source)
  const cart = useAppSelector(state => state.shopify.cart)
  const lineItem = cart[product.variants[0]!.id]
  const dispatch = useAppDispatch()

  const [quantity, setQuantity] = React.useState(isCart ? lineItem?.quantity || 1 : 1)

  React.useEffect(() => {
    if (isCart && lineItem) {
      setQuantity(lineItem.quantity)
    }
  }, [lineItem])

  const initialSellingPlanId = isCart ? lineItem?.sellingPlanId : null

  const [sellingPlan, setSellingPlan] = React.useState<PersistedSellingPlan | null>(
    product.sellingPlans?.find(sp => sp.selling_plan_id === initialSellingPlanId) || null,
  )
  const [isSubscription, setIsSubscription] = React.useState(!!sellingPlan)

  const handleSellingPlanChange = (sellingPlan: PersistedSellingPlan | null) => {
    setSellingPlan(sellingPlan)
    // We only want to add a selling plan to the cart item if the subscription option
    // is selected. Setting selling plan to null is always allowed.
    if (isCart && (sellingPlan === null || isSubscription)) {
      dispatch(changeLineItemSellingPlan(product.variants[0]!.id, sellingPlan?.selling_plan_id))
    }
  }

  const handleIsSubscriptionChange = (isSubscription: boolean) => {
    setIsSubscription(isSubscription)
    if (isCart) {
      dispatch(changeIsSubscription(product.variants[0]!.id, isSubscription))
    }
  }

  const [lastSellingPlan, setLastSellingPlan] = React.useState<PersistedSellingPlan | null>(null)

  React.useEffect(() => {
    if (sellingPlan) {
      setLastSellingPlan(sellingPlan)
    }
  }, [sellingPlan])

  const priceTimesQuantity = {
    amount: (parseFloat(price.amount) * quantity).toString(),
    currencyCode: price.currencyCode,
  }

  const subscriptionPrice = shopifyService.applyPriceModifiers(
    product,
    priceTimesQuantity.amount,
    isSubscription ? sellingPlan?.selling_plan_id : undefined,
  )

  return (
    <div
      className={classNames(styles.ProductOptions, {
        [styles.ProductOptions___inCart]: isCart,
      })}
    >
      {!isCart && (
        <div className={styles.ProductOptions__quantity}>
          {/* The id is used for aria-labelledby in the quantity control */}
          <p className={styles.ProductOptions__quantityTitle} id={`quantity-${product.title}`}>
            Amount
          </p>
          <ProductQuantityControl
            quantity={quantity}
            minimum={1}
            maximum={9}
            onChange={setQuantity}
            productName={product.title}
          />
        </div>
      )}
      <div className={styles.ProductOptions__frequency}>
        <ProductFrequencyControl
          product={product}
          price={priceTimesQuantity}
          legend="Purchase option"
          initialSellingPlanId={lastSellingPlan?.selling_plan_id}
          source={source}
          onIsSubscriptionChange={handleIsSubscriptionChange}
          onSellingPlanChange={handleSellingPlanChange}
        />
      </div>
      {source === ProductOptionSource.ProductPage && (
        <div className={styles.ProductOptions__addToCartButton}>
          <AddToCartButton
            product={product}
            quantity={quantity}
            size="large"
            source={AddToCartSources.ProductPage}
            price={new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: priceTimesQuantity.currencyCode,
            }).format(Number.parseFloat(subscriptionPrice))}
            sellingPlanId={isSubscription ? sellingPlan?.selling_plan_id : undefined}
            isSubscription={isSubscription}
          />
        </div>
      )}
      {isSubscription && (
        <p className={styles.ProductOptions__disclaimer}>
          Auto-renews, skip or cancel anytime.{` `}
          <a href="/other/legal/terms" className={styles.ProductFrequencyControl__disclaimerLink}>
            View&nbsp;subscription&nbsp;policy
          </a>
        </p>
      )}
    </div>
  )
}

export enum ProductOptionSource {
  ProductPage = 'ProductPage',
  MiniCart = 'MiniCart',
  Cart = 'Cart',
}

export const query = graphql`
  fragment SellingPlansFragment on ShopifyProduct {
    sellingPlans {
      selling_plan_id
      delivery_interval_count
      delivery_interval
      billing_interval_count
      billing_interval
      pricing_policy_fixed_adjustment_type
      pricing_policy_fixed_adjustment_value
    }
  }
`
