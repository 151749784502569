import classNames from 'classnames'
import React from 'react'
import { MenuArticleModal } from '../menu-article-modal/MenuArticleModal.component'
import styles from './MenuCategoryItem.module.scss'

export interface MobileMenuArticle {
  text: string
  href: string
  thumbnail: string
  alt: string
  button?: {
    cta: string
    href: string
  }
}

export interface MobileMenuCategory {
  category: string
  articles: MobileMenuArticle[]
  button?: {
    cta: string
    href: string
  }
}

export interface MobileMenuButton {
  cta: string
  href: string
}

interface MenuCategoryItemProps {
  categoryData: MobileMenuCategory
  isDesktop: boolean
  onSelect: () => void
  isSelected: boolean
}

export const MenuCategoryItem: React.FC<MenuCategoryItemProps> = ({
  categoryData,
  isDesktop,
  onSelect,
  isSelected,
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const modalId = `modal-${categoryData.category.replaceAll(/\s+/g, '-').toLowerCase()}`

  const openModal = React.useCallback(() => {
    setIsModalOpen(true)
  }, [])

  const closeModal = React.useCallback(() => {
    setIsModalOpen(false)
  }, [])

  React.useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        closeModal()
      }
    }
    if (isModalOpen) {
      document.addEventListener('keydown', handleKeyDown)
    }
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [isModalOpen, closeModal])

  const handleClick = () => {
    if (isDesktop) {
      onSelect()
    } else {
      openModal()
    }
  }

  return (
    <>
      <button
        onClick={handleClick}
        className={classNames(styles.MenuCategoryItem__button, {
          [styles.MenuCategoryItem__button___selected]: isSelected,
        })}
        aria-controls={isDesktop ? 'desktop-articles-section' : modalId}
        {...(!isDesktop && {
          'aria-haspopup': 'dialog',
          'aria-expanded': isModalOpen,
        })}
      >
        <div className={styles.MenuCategoryItem__buttonContainer}>
          {categoryData.category}
          <img
            src="/icons/faq-chevron-black.svg"
            className={styles.MenuCategoryItem__chevron}
            aria-hidden="true"
            alt="Dropdown chevron"
          />
        </div>
      </button>

      {!isDesktop && isModalOpen && (
        <MenuArticleModal
          isDesktop={isDesktop}
          modalId={modalId}
          categoryData={categoryData}
          closeModal={closeModal}
        />
      )}
    </>
  )
}
