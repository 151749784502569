import { _numberEnumValues, LocalTime, localTime } from '@naturalcycles/js-lib'
import { SupportedLocale } from '@src/cnst/translations.cnst'
import languageContext from '@src/context/language.context'
import { PageTypes, usePageType } from '@src/hooks/usePageType'
import classNames from 'classnames'
import React from 'react'
import { useScrollYPosition } from 'react-use-scroll-position'
import Button from '../button/Button.component'
import { ClientOnly } from '../client-only/ClientOnly.component'
import { DatoCmsButton } from '../column-content/ColumnContent.component'
import styles from './TimerBanner.module.scss'

export interface TimerBannerProps {
  /**
   * HTML string for the large heading
   *
   * Shown in the expanded banner when the user is at the top of the page
   */
  headingLarge: string
  /**
   * HTML string for the small heading
   *
   * Shown in the smaller banner when the user has scrolled past the top of the page
   */
  headingSmall: string
  /**
   * The time when the banner should disappear
   */
  expiration: LocalTime
  button: Pick<DatoCmsButton, 'text' | 'url'>
}

/**
 * A sticky banner that disappears after the timer runs out
 * and shrinks on scroll to hide the timer
 */
export const TimerBanner = ({
  headingLarge,
  headingSmall,
  expiration,
  button,
}: TimerBannerProps): React.ReactNode => {
  const scrollY = useScrollYPosition()

  function addLeadingZero(value: number): string {
    return value < 10 ? `0${value}` : String(value)
  }

  const [now, setNow] = React.useState(localTime.now())

  let interval: NodeJS.Timeout

  React.useEffect(() => {
    interval = setInterval(() => {
      setNow(localTime.now())
    }, 200)

    return () => clearInterval(interval)
  })

  const { locale } = React.useContext(languageContext)

  const enUSFields: TimmerFieldLabelTranslations = {
    [TimerFieldType.Days]: 'days',
    [TimerFieldType.Hours]: 'hours',
    [TimerFieldType.Minutes]: 'min',
    [TimerFieldType.Seconds]: 'sec',
  }

  const esUSFields: TimmerFieldLabelTranslations = {
    [TimerFieldType.Days]: 'días',
    [TimerFieldType.Hours]: 'horas',
    [TimerFieldType.Minutes]: 'min',
    [TimerFieldType.Seconds]: 'seg',
  }

  const ptBRFields: TimmerFieldLabelTranslations = {
    [TimerFieldType.Days]: 'dias',
    [TimerFieldType.Hours]: 'horas',
    [TimerFieldType.Minutes]: 'min',
    [TimerFieldType.Seconds]: 'seg',
  }

  const svSEFields: TimmerFieldLabelTranslations = {
    [TimerFieldType.Days]: 'dagar',
    [TimerFieldType.Hours]: 'timmar',
    [TimerFieldType.Minutes]: 'min',
    [TimerFieldType.Seconds]: 'sek',
  }

  const translations: Record<SupportedLocale, TimmerFieldLabelTranslations> = {
    [SupportedLocale.enUS]: enUSFields,
    [SupportedLocale.enCA]: enUSFields,
    [SupportedLocale.esUS]: esUSFields,
    [SupportedLocale.ptBR]: ptBRFields,
    [SupportedLocale.svSE]: svSEFields,
  }

  const time: Record<TimerFieldType, string> = {
    [TimerFieldType.Days]: addLeadingZero(expiration.diff(now, 'day')),
    [TimerFieldType.Hours]: addLeadingZero(expiration.diff(now, 'hour') % 24),
    [TimerFieldType.Minutes]: addLeadingZero(expiration.diff(now, 'minute') % 60),
    [TimerFieldType.Seconds]: addLeadingZero(expiration.diff(now, 'second') % 60),
  }

  const [isExpired, setIsExpired] = React.useState(false)

  const shouldGrow = usePageType() === PageTypes.StandardHome

  React.useEffect(() => {
    if (!shouldGrow) {
      return
    }
    if (expiration.diff(now, 'second') <= 0) {
      setIsExpired(true)
      clearInterval(interval)
    }
  }, [expiration, now])

  if (isExpired) {
    return null
  }

  const timerFields: TimerField[] = _numberEnumValues(TimerFieldType).map(timerFieldType => ({
    time: time[timerFieldType],
    label: translations[locale][timerFieldType],
  }))

  const isLarge = shouldGrow && scrollY < 15

  return (
    <ClientOnly>
      {!isExpired && (
        <section
          className={classNames(styles.TimerBanner, {
            [styles.TimerBanner___large]: isLarge,
            [styles.TimerBanner___small]: !isLarge,
          })}
        >
          {isLarge ? (
            <div className={styles.TimerBanner__content}>
              <h3
                className={styles.TimerBanner__heading}
                dangerouslySetInnerHTML={{ __html: headingLarge }}
              />
              <div className={styles.TimerBanner__timer}>
                {timerFields.map(({ time, label }) => (
                  <div className={styles.TimerBanner__timer__field} key={label}>
                    <span className={styles.TimerBanner__timer__time}>{time}</span>
                    <span className={styles.TimerBanner__timer__label}>{label}</span>
                  </div>
                ))}
              </div>
              <Button href={button.url} size="small">
                {button.text}
              </Button>
            </div>
          ) : (
            <div className={styles.TimerBanner__content}>
              <h3
                className={styles.TimerBanner__heading}
                dangerouslySetInnerHTML={{ __html: headingSmall }}
              />
              <div className={styles.TimerBanner__close} onClick={() => setIsExpired(true)}>
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 1.13514L7.13513 6L12 10.8649L10.8649 12L6 7.13513L1.13514 12L-7.73249e-08 10.8649L4.86487 6L-7.73249e-08 1.13514L1.13514 -7.73249e-08L6 4.86487L10.8649 -7.73249e-08L12 1.13514Z"
                    fill="#72035D"
                  />
                </svg>
              </div>
            </div>
          )}
        </section>
      )}
    </ClientOnly>
  )
}

enum TimerFieldType {
  Days = 1,
  Hours = 2,
  Minutes = 3,
  Seconds = 4,
}

type TimmerFieldLabelTranslations = Record<TimerFieldType, string>

interface TimerField {
  time: string
  label: string
}
