import Button from '@src/components/button/Button.component'
import { Breakpoints } from '@src/hooks/useBreakpoint'
import { useWindowDimensions } from '@src/hooks/useWindowDimensions'
import classNames from 'classnames'
import React from 'react'
import { MenuArticleModal } from '../menu-article-modal/MenuArticleModal.component'
import {
  MenuCategoryItem,
  MobileMenuButton,
  MobileMenuCategory,
} from '../menu-category-item/MenuCategoryItem.component'
import styles from './MenuLibrary.module.scss'

export interface MenuLibraryProps {
  label: string
  categories: MobileMenuCategory[]
  button: MobileMenuButton
  desktopInfo?: {
    heading: string
    context: string
    button: MobileMenuButton
  }
}

export const MenuLibrary: React.FC<MenuLibraryProps> = ({
  label,
  categories,
  button,
  desktopInfo,
}) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [selectedCategory, setSelectedCategory] = React.useState<MobileMenuCategory>(categories[0]!)
  const { width } = useWindowDimensions()
  const isDesktop = width && width >= Breakpoints.bpLarge
  const categoryListRef = React.useRef<HTMLDivElement>(null)
  const buttonRef = React.useRef<HTMLButtonElement>(null)
  const menuId = 'mobile-menu-library-categories'

  const toggleDropdown = React.useCallback(() => {
    setIsOpen(prevOpen => !prevOpen)
    if (isOpen) {
      setSelectedCategory(categories[0]!)
    }
  }, [isOpen, categories])

  const handleCategorySelect = React.useCallback((category: MobileMenuCategory) => {
    setSelectedCategory(category)
  }, [])

  const closeMenu = React.useCallback(() => {
    setIsOpen(false)
    setSelectedCategory(categories[0]!)
    // Shift focus back to the toggle button for accessibility
    buttonRef.current?.focus()
  }, [categories])

  // Prevent background scroll when menu is open on mobile
  React.useEffect(() => {
    if (isOpen && !isDesktop) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }

    return () => {
      document.body.style.overflow = ''
    }
  }, [isOpen, isDesktop])

  // Handle ESC key press and outside clicks
  React.useEffect(() => {
    if (!isOpen) return

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        closeMenu()
      }
    }

    const handleClickOutside = (e: MouseEvent) => {
      if (categoryListRef.current && !categoryListRef.current.contains(e.target as Node)) {
        closeMenu()
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpen, closeMenu])

  return (
    <div
      ref={categoryListRef}
      className={classNames(styles.MenuLibrary, {
        [styles.MenuLibrary___open]: isOpen,
      })}
    >
      <button
        ref={buttonRef}
        aria-haspopup="listbox"
        aria-expanded={isOpen}
        aria-controls={menuId}
        onClick={toggleDropdown}
        className={styles.MenuLibrary__button}
      >
        <div className={styles.MenuLibrary__buttonContainer}>
          {label}
          <img
            className={styles.MenuLibrary__chevron}
            data-open={isOpen}
            src="/icons/iconsAb280/chevron-icon.svg"
            aria-hidden="true"
          />
        </div>
      </button>

      {isOpen && (
        <>
          <div className={styles.MenuLibrary__categoryList}>
            {desktopInfo && (
              <div className={styles.MenuLibrary__desktopInfo}>
                <h2>{desktopInfo.heading}</h2>
                <p>{desktopInfo.context}</p>
                <div className={styles.MenuLibrary__desktopInfoButton} onClick={closeMenu}>
                  <Button size="small" href={desktopInfo.button.href}>
                    {desktopInfo.button.cta}
                  </Button>
                </div>
              </div>
            )}

            <ul
              id="mobile-menu-library-categories"
              aria-label={`${label} categories`}
              className={styles.MenuLibrary__categories}
            >
              {categories.map(categoryData => (
                <li key={categoryData.category}>
                  <MenuCategoryItem
                    categoryData={categoryData}
                    isDesktop={!!isDesktop}
                    onSelect={() => handleCategorySelect(categoryData)}
                    isSelected={selectedCategory === categoryData}
                  />
                </li>
              ))}
            </ul>

            {isDesktop && selectedCategory && (
              <MenuArticleModal
                isDesktop={isDesktop}
                categoryData={selectedCategory}
                modalId={`desktop-articles-section`}
                closeMenu={closeMenu}
                closeModal={() => setSelectedCategory(categories[0]!)}
              />
            )}

            <div className={styles.MenuLibrary__sectionButton} onClick={closeMenu}>
              <Button theme="ghost" href={button.href}>
                {button.cta}
              </Button>
            </div>

            <button
              className={styles.MenuLibrary__closeButton}
              aria-label="close"
              onClick={toggleDropdown}
            >
              <img src="/icons/iconsAb280/close-icon.svg" alt="Close" aria-hidden="true" />
            </button>
          </div>
          <div className={styles.MenuLibrary__overlay} />
        </>
      )}
    </div>
  )
}
