import Button from '@src/components/button/Button.component'
import { APP_STORE_URL } from '@src/helpers/env'
import { mixpanelService } from '@src/srv/mixpanel.service'
import React from 'react'
import { LocalizedFooterIcon } from '../localized-footer-icon/LocalizedFooterIcon.component'
import { NcLink } from '../nc-link/NcLink.component'
import styles from './AppStoreIcon.module.scss'

interface AppStoreIconProps {
  href?: string
  theme?: string
}

const AppStoreIcon = ({ href = APP_STORE_URL, theme = 'official' }: AppStoreIconProps) => {
  const elementName = 'app-download-button-ios'

  const onClick = (event: any) => {
    event.preventDefault()
    mixpanelService.trackLink(elementName, href)
  }

  return (
    <>
      {theme === 'official' && (
        <NcLink to={href} onClick={onClick}>
          <LocalizedFooterIcon
            icon="app-store"
            className={styles.AppStoreIcon__image}
            alt="App Store"
            loading="lazy"
          />
        </NcLink>
      )}

      {theme === 'button' && (
        <Button size="flexible" theme="brandPurple" elementName={elementName} href={href}>
          <div className={styles.AppStoreIcon__button}>
            <img
              className={styles.AppStoreIcon__icon}
              src={`/icons/app-store-mini.svg`}
              alt="App Store"
              loading="lazy"
            />
            App Store
          </div>
        </Button>
      )}
    </>
  )
}

export default AppStoreIcon
