import { _round } from '@naturalcycles/js-lib'
import { Price, ProductKey, ProductType } from '@naturalcycles/shared'
import { SupportedLocale } from '@src/cnst/translations.cnst'
import LanguageContext from '@src/context/language.context'
import { useAppSelector } from '@src/hooks/useAppSelector'
import { useTranslation } from '@src/hooks/useTranslation'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import styles from './BannerDiscountCode.module.scss'

export interface BannerDiscountCodeProps {
  page?: 'influencer' | 'regular'
}

export const BannerDiscountCode = ({ page }: BannerDiscountCodeProps): ReactElement => {
  const [display, setDisplay] = useState(false)
  const [showDropdown, setShowDropdown] = useState(false)

  const [code, setCode] = useState<string>()
  const [percentage, setPercentage] = useState<string>()
  const [asAmount, setAsAmount] = useState<boolean>()
  const [includesThermometer, setIncludesThermometer] = useState<boolean>()
  const [name, setName] = useState<string>()

  // Get values from Redux
  const reduxCode = useAppSelector(state => state.discountCode.code)
  const reduxPercentage = useAppSelector(state => state.discountCode.percentage)
  const reduxName = useAppSelector(state => state.discountCode.name)
  const reduxAsAmount = useAppSelector(state => state.discountCode.asAmount)
  const reduxIncludesThermometer = useAppSelector(state => state.discountCode.includesThermometer)
  const userLocale = useAppSelector(state => state.userLocale)
  const product = useAppSelector(state => state.product)
  const { locale } = useContext(LanguageContext)

  useEffect(() => {
    // Use values saved in the Session Storage if no recent value was triggered
    setCode(reduxCode || window.sessionStorage.getItem('discountCode') || '')
    setPercentage(reduxPercentage || window.sessionStorage.getItem('discountPercentage') || '')
    setName(reduxName || window.sessionStorage.getItem('discountName') || '')
    setAsAmount(reduxAsAmount || window.sessionStorage.getItem('discountAsAmount') === 'true')
    setIncludesThermometer(
      reduxIncludesThermometer || window.sessionStorage.getItem('includesThermometer') === 'true',
    )
  }, [reduxCode, reduxPercentage, reduxName, reduxAsAmount, reduxIncludesThermometer])
  const translate = useTranslation()

  const discountAmount = React.useMemo(() => {
    const yearlyPriceString = product.items.find(item => item.key === ProductKey.YEAR)?.price
    if (!yearlyPriceString || !asAmount || !percentage) {
      return
    }
    const percentageDigits = /(\d+)/.exec(percentage)?.[0]
    if (!percentageDigits) {
      return
    }
    const { pricingCountry, pricingCurrency } = userLocale
    const percentageNumber = parseFloat(percentageDigits)
    if (isNaN(percentageNumber)) {
      return
    }
    const yearlyPrice = new Price(yearlyPriceString)
    let amount = (yearlyPrice.amountWithoutDiscount * percentageNumber) / 100
    if (includesThermometer) {
      const thermometer = product.items.find(item => item.type === ProductType.SHOP)
      if (thermometer) {
        const thermometerPrice = new Price(thermometer.price)
        amount += thermometerPrice.amount
      }
    }
    const rounded = _round(amount, 1)
    const price = new Price(`${rounded}[${pricingCurrency}]`)
    return price.toLocaleString(pricingCountry)
  }, [asAmount, includesThermometer, percentage, product, userLocale])

  const amountCopy = React.useMemo(() => {
    if (!discountAmount && !percentage) {
      return undefined
    }
    const amount = discountAmount || percentage!
    return locale !== SupportedLocale.enUS && amount.includes('OFF') ? amount.slice(0, 3) : amount
  }, [discountAmount, percentage, locale])

  useEffect(() => {
    if (code) {
      setDisplay(true)
    }
  }, [code])

  if (display) {
    return (
      <div className={styles.BannerDiscountCode}>
        <div className={styles.BannerDiscountCode__layout}>
          <p className={styles.BannerDiscountCode__text}>
            {page === 'influencer' ? (
              translate('discount-influencer')
            ) : amountCopy ? (
              <>
                {translate('discount-saved-1')}
                <span className={styles.BannerDiscountCode__textHighlight}> {amountCopy} </span>
                {translate('discount-saved-2')}
              </>
            ) : (
              <>{translate('discount-offer')}</>
            )}
          </p>
          {!page && (
            <>
              <button
                onClick={() => setShowDropdown(!showDropdown)}
                className={
                  showDropdown
                    ? styles.BannerDiscountCode__arrowUp
                    : styles.BannerDiscountCode__arrowDown
                }
                aria-label="more discount information"
              >
                {/* eslint-disable-next-line jsx-a11y/alt-text */}
                <img src="/icons/faq-chevron-black.svg" aria-hidden={true} />
              </button>

              <p
                className={
                  !showDropdown
                    ? styles.BannerDiscountCode__dropdownHidden
                    : styles.BannerDiscountCode__dropdownVisible
                }
              >
                {name ? (
                  <>
                    {translate('discount-referral-code-1')}{' '}
                    <span className={styles.BannerDiscountCode__dropdownHighlightName}>{name}</span>{' '}
                    {translate('discount-referral-code-2')}
                  </>
                ) : (
                  <>
                    {translate('discount-saved-code-1')}{' '}
                    <span className={styles.BannerDiscountCode__dropdownHighlightCode}>{code}</span>{' '}
                    {translate('discount-saved-code-2')}
                  </>
                )}
              </p>
            </>
          )}
        </div>
      </div>
    )
  }
  return <></>
}
