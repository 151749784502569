import React from 'react'
import { NcLink } from '../nc-link/NcLink.component'

interface LinkProps {
  uid?: string
  children: any
  href: string
  className?: string
  target?: string
  ariaLabel?: string
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void
}

const Link = ({ children, className = '', href, ariaLabel = '', onClick, uid }: LinkProps) => {
  // gatsby optimizations don't work for external links either
  const isExternal = href.startsWith('http')

  // gatsby optimizations super duper don't work for mailto links!
  const isMailto = href.startsWith('mailto:')

  if (isExternal || isMailto) {
    return (
      <a uid={uid} className={className} href={href} {...(onClick ? { onClick } : {})}>
        {children}
      </a>
    )
  }

  return (
    <NcLink
      uid={uid}
      to={href}
      className={className}
      aria-label={ariaLabel}
      {...(onClick ? { onClick } : {})}
    >
      {children}
    </NcLink>
  )
}

export default Link
