import { _stringEnumKeyOrUndefined, _stringEnumValues, isClientSide } from '@naturalcycles/js-lib'
import { SupportedLocale, SupportedUrl } from '@src/cnst/translations.cnst'
import { botDetectionService, isE2eTest } from '@src/helpers/env'
import { getDomain, getTrafficOrigin } from '@src/helpers/trafficOriginUtil'
import * as mixpanel from 'mixpanel-browser'

Object.assign(globalThis, { mixpanel })

const GATSBY_MIXPANEL_TOKEN = process.env['GATSBY_MIXPANEL_TOKEN']!

class MixpanelService {
  // Mixpanel is disabled if :
  // - it runs on ServerSide (SSR)
  // - it's a bot (or DevTools protocol is used)
  // - it's an e2e test
  enabled = isClientSide() && !botDetectionService.isBotOrCDP() && !isE2eTest()

  init(): void {
    const options: Partial<mixpanel.Config> = {
      autotrack: false,
      disable_notifications: true,
      cross_subdomain_cookie: process.env['GATSBY_ENV'] === 'production',
    }

    mixpanel.init(GATSBY_MIXPANEL_TOKEN, options)
  }

  getDistinctId(): string | undefined {
    if (!this.enabled) return
    return mixpanel.get_distinct_id()
  }

  track(name: string, props = {}) {
    if (!this.enabled) return
    this._track(name, props)
  }

  trackImpression(): void {
    if (!this.enabled) return
    let path = document.location.pathname

    const [first, ...rest] = path.split(/\//).filter(Boolean)
    const localeKey = _stringEnumKeyOrUndefined(SupportedUrl, first) || 'enUS'

    // Strip language prefixes from all URLs so that impressions for all
    // translated versions of a page are grouped under the URL of the main
    // US English version of the page.
    if (_stringEnumValues(SupportedUrl).includes(first! as SupportedUrl)) {
      path = `/${rest.join('/')}`
    }

    // Strip trailing slashes from all URLs so that there's no time-consuming
    // Mixpanel admin work required to clean up data for analysis.
    if (path !== '/' && path.endsWith('/')) {
      path = path.slice(0, -1)
    }

    this._track(`Viewed ${path}`, { locale: SupportedLocale[localeKey] })
  }

  testImpression(testId: string, testGroup: string): void {
    if (!this.enabled) return

    // Tracking with new event format
    this._track(`Experiment IMPRESSION`, {
      experiment: testId,
      assignment: testGroup,
    })

    // TODO: remove when the backend has removed this too from legacy tracking
    // Tracking with legacy event format
    this._track(`AB-${testId}-IMPRESSION`, {
      assignment: testGroup,
      experiment: testId,
    })
  }

  trackClick(element: string, extraProps?: any): void {
    if (!this.enabled) return
    this._track('WebClick', {
      element,
      ...extraProps,
    })
  }

  trackLink(element: string, href: string, extraProps?: any): void {
    if (!this.enabled) return
    const navigate = () => (window.location.href = href)
    setTimeout(navigate, 300)
    this._track(
      'WebClick',
      {
        element,
        href,
        ...extraProps,
      },
      navigate,
    )
  }

  private _track(
    event_name: string,
    properties?: mixpanel.Dict,
    optionsOrCallback?: mixpanel.RequestOptions | mixpanel.Callback,
    callback?: mixpanel.Callback,
  ): void {
    if (!this.enabled) return
    const props = {
      ...properties,
      referrer: getDomain(document.referrer) || '(direct)',
      trafficOrigin: getTrafficOrigin(document),
      ...(window.Cookiebot?.hasResponse && {
        'CookieConsent.statistics': window.Cookiebot.consent.statistics,
        'CookieConsent.marketing': window.Cookiebot.consent.marketing,
      }),
      repo: 'ncweb',
      botReason: botDetectionService.getBotReason() || undefined,
      userAgent: botDetectionService.isBotOrCDP() ? navigator?.userAgent : undefined,
      cdp: botDetectionService.isCDP() || undefined,
      e2e: isE2eTest(),
    }
    mixpanel.track(event_name, props, optionsOrCallback, callback)
    if (process.env?.['GATSBY_ENV'] !== 'production') {
      console.log(`[MixpanelService] ${event_name}`, properties)
    }
  }
}

export const mixpanelService = new MixpanelService()
