import LanguageContext from '@src/context/language.context'
import { getShopMenu, getSiteMenu, NavMenu } from '@src/helpers/menu'
import { ConsumerPageTypes, ShopPageTypes, usePageType } from '@src/hooks/usePageType'
import { useTranslation } from '@src/hooks/useTranslation'
import { store } from '@src/store'
import { useContext, useEffect, useState } from 'react'

export const useNavMenuDesktop = (): { menu: NavMenu | null } => {
  const pageType = usePageType()
  const consumerHeaderPageTypes = [...ConsumerPageTypes]
  const { locale } = useContext(LanguageContext)
  const translate = useTranslation()
  const { country } = store.getState().geolocation
  const {
    shopify: { shippingLocation },
  } = store.getState()
  const [menu, setMenu] = useState<NavMenu | null>(null)

  useEffect(() => {
    let menuValue: NavMenu | null = null

    if (ShopPageTypes.includes(pageType)) {
      menuValue = getShopMenu(shippingLocation)
    } else if (consumerHeaderPageTypes.includes(pageType)) {
      menuValue = getSiteMenu(translate, locale, country)
    }

    setMenu(menuValue)
  }, [pageType, country, shippingLocation, locale])

  return { menu }
}
