import classNames from 'classnames'
import React from 'react'
import styles from './MenuDropdown.module.scss'

export interface MenuDropdownProps {
  label: string
  listItems: {
    label: string
    subLabel: string
    href: string
    iconPath: string
    iconAlt: string
  }[]
}

export const MenuDropdown: React.FC<MenuDropdownProps> = ({ label, listItems }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const dropdownRef = React.useRef<HTMLDivElement>(null)
  const buttonRef = React.useRef<HTMLButtonElement>(null)
  const menuId = `mobile-menu-dropdown-${label.replaceAll(/\s+/g, '-').toLowerCase()}`

  const toggleDropdown = React.useCallback(() => {
    setIsOpen(prevOpen => !prevOpen)
  }, [])

  const closeDropdown = React.useCallback(() => {
    setIsOpen(false)
    // Return focus to the toggle button for accessibility
    buttonRef.current?.focus()
  }, [])

  React.useEffect(() => {
    if (!isOpen) return

    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        closeDropdown()
      }
    }

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closeDropdown()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [isOpen, closeDropdown])

  React.useEffect(() => {
    if (isOpen && dropdownRef.current) {
      const firstLink = dropdownRef.current.querySelector<HTMLAnchorElement>('a')
      // For accessibility shift focus to the first item in the dropdown when it opens
      firstLink?.focus()
    }
  }, [isOpen])

  return (
    <div
      className={classNames(styles.MenuDropdown, {
        [styles.MenuDropdown___open]: isOpen,
      })}
      ref={dropdownRef}
    >
      <button
        ref={buttonRef}
        aria-haspopup="listbox"
        aria-expanded={isOpen}
        aria-controls={menuId}
        onClick={toggleDropdown}
        className={styles.MenuDropdown__button}
      >
        <div className={styles.MenuDropdown__buttonContainer}>
          {label}
          <img
            className={styles.MenuDropdown__chevron}
            data-open={isOpen}
            src="/icons/iconsAb280/chevron-icon.svg"
            alt=""
            aria-hidden="true"
          />
        </div>
      </button>

      {isOpen && (
        <>
          <ul
            id={menuId}
            role="listbox"
            aria-label={`${label} options`}
            className={styles.MenuDropdown__container}
          >
            {listItems.map(item => (
              <li className={styles.MenuDropdown__item} key={item.href} onClick={closeDropdown}>
                <a
                  href={item.href}
                  className={styles.MenuDropdown__itemContainer}
                  role="option"
                  tabIndex={0}
                >
                  <p className={styles.MenuDropdown__label}>{item.label}</p>
                  <p className={styles.MenuDropdown__sublabel}>{item.subLabel}</p>
                  <img
                    aria-hidden="true"
                    src={item.iconPath}
                    alt={item.iconAlt}
                    className={styles.MenuDropdown__icon}
                  />
                </a>
              </li>
            ))}
          </ul>
          <div className={styles.MenuDropdown__overlay} />
        </>
      )}
    </div>
  )
}
