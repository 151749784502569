import { BackendResponseFM } from '@naturalcycles/shared'
import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction,
  ThunkDispatch,
} from '@reduxjs/toolkit'
import { getUtmQueryParams } from '@src/helpers/queryParams'
import { ShippingCurrency, ShippingLocations } from '@src/shop/cnst/shopify.cnst'
import { userLocaleSlice } from '@src/store/userLocale.slice'
import Cookies from 'js-cookie'
import shopify, { ShopifyState } from '../shop/store/shopify.slice'
import cyclemattersSearch from './cyclemattersSearch/cyclemattersSearch.slice'
import discountCode from './discountCode/discountCode.slice'
import experiment from './experiment/experiment.slice'
import geolocation, { GeolocationCountries } from './geolocation/geolocation.slice'
import mobileMenu from './mobileMenu/mobileMenu.slice'
import pathname from './pathname/pathname.slice'
import popup, { PopupState, PopupStatuses } from './popup/popup.slice'
import product from './product/product.slice'
const reducer = combineReducers({
  mobileMenu: mobileMenu.reducer,
  geolocation: geolocation.reducer,
  popup: popup.reducer,
  shopify: shopify.reducer,
  discountCode: discountCode.reducer,
  cyclemattersSearch: cyclemattersSearch.reducer,
  pathname: pathname.reducer,
  experiment: experiment.reducer,
  userLocale: userLocaleSlice.reducer,
  product: product.reducer,
})

// If the user is coming from the app or with a discount code parameter we disable all popups
const popupFreeSources = ['NCApp', 'check-websignup', 'optimove', 'attentive']
let popupStatus = PopupStatuses.Closed

if (typeof window !== 'undefined') {
  const searchParams = new URLSearchParams(window.location.search)
  const utms = getUtmQueryParams()
  const hasDiscountCode = searchParams.has('code')

  if (utms['utm_source'] && (popupFreeSources.includes(utms['utm_source']) || hasDiscountCode)) {
    popupStatus = PopupStatuses.Disabled
  }

  // check utm and create a cookie for signup to parse
  if (Object.keys(utms).length) {
    const utmParams = JSON.stringify(utms)
    if (process.env['GATSBY_ENV'] !== 'production') {
      // We're not strictly on naturalcycles.com unless we're in production.
      // This is for being able to test that the utms are being set correctly.
      Cookies.set('utms', utmParams, { expires: 30 })
    } else {
      Cookies.set('utms', utmParams, { expires: 30, domain: 'naturalcycles.com' })
    }
  }
}

interface PreloadedStateProps {
  popup: PopupState
  shopify: ShopifyState
}

const preloadedState: PreloadedStateProps = {
  popup: {
    status: popupStatus,
    impressions: {},
  },
  shopify: {
    cart: {},
    currency: ShippingCurrency.ROW,
    alteredRegion: false,
    miniCartOpened: false,
  },
}

if (typeof window !== 'undefined') {
  const shippingLocation = sessionStorage.getItem('Region:')
  const expectedLocation = sessionStorage.getItem('ExpectedLocation:')
  if (shippingLocation) {
    preloadedState.shopify.shippingLocation = shippingLocation as ShippingLocations
    preloadedState.shopify.currency =
      shippingLocation === ShippingLocations.Blocked
        ? ShippingCurrency.ROW
        : ShippingCurrency[
            shippingLocation as Exclude<keyof typeof ShippingLocations, 'Blocked'>
          ] || ShippingCurrency.ROW
  }

  if (expectedLocation) {
    preloadedState.shopify.expectedLocation = expectedLocation as ShippingLocations
  }

  Object.entries(localStorage).forEach(([key, value]) => {
    const popupCampaign = /<Popup name="([^"]+)" \/>/.exec(key)
    if (popupCampaign) {
      ;(preloadedState.popup.impressions as any)[popupCampaign[1]!] = Number.parseInt(value, 10)
    }
  })

  const cart = sessionStorage.getItem('cart')
  if (cart) {
    preloadedState.shopify.cart = JSON.parse(cart)
  }
}

export const store = configureStore({
  reducer,
  preloadedState,
})

export const onBackendResponse =
  (br: BackendResponseFM): Thunk =>
  dispatch => {
    if (br.experiment) {
      dispatch(experiment.actions.replaceExperiment(br.experiment))
    }

    if (br.userLocale) {
      dispatch(userLocaleSlice.actions.replace(br.userLocale))
      // Right now we're basing our "geolocation" service upon webInit `br.userLocale.country`
      // it does geolocationDone.resolve() inside
      const firebaseOverride = Cookies.get('firebase-country-override')
      if (firebaseOverride) {
        dispatch(geolocation.actions.success(firebaseOverride as GeolocationCountries))
      } else {
        dispatch(geolocation.actions.success(br.userLocale.country as GeolocationCountries))
      }
    }

    if (br.product) {
      dispatch(product.actions.replace(br.product))
    }
  }

export type RootState = ReturnType<typeof reducer>
export type Thunk = ThunkAction<void, RootState, unknown, Action<string>>
export type Dispatch = ThunkDispatch<RootState, unknown, Action<string>>

const getStore = () => store
export default getStore

if (typeof window !== 'undefined') {
  ;(window as any).getState = () => store.getState()
}
