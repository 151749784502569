import React from 'react'
import styles from './MenuLink.module.scss'

interface MenuLinkProps {
  href: string
  label: string
}

export const MenuLink = React.memo(({ href, label }: MenuLinkProps) => {
  return (
    <a href={href} className={styles.MenuLink}>
      {label}
    </a>
  )
})
