import { _assert } from '@naturalcycles/js-lib'
import { PersistedSellingPlan, ShopifyPrice, ShopifyProduct } from '../store/shopify.model'

class ShopifyService {
  applySellingPlanDiscount(
    product: ShopifyProduct,
    amount: string,
    sellingPlanId: string | undefined,
  ): string {
    if (!sellingPlanId) {
      return amount
    }
    const sellingPlan = product.sellingPlans?.find(plan => plan.selling_plan_id === sellingPlanId)
    if (sellingPlan?.pricing_policy_fixed_adjustment_value) {
      const discount = 1 - sellingPlan.pricing_policy_fixed_adjustment_value / 100
      return (parseFloat(amount) * discount).toFixed(2)
    }
    return amount
  }

  private getProductPrice(product: ShopifyProduct, currencyCode: string): ShopifyPrice['price'] {
    if (currencyCode === 'USD') {
      return product.variants[0]!.usd.price
    }
    if (currencyCode === 'GBP') {
      return product.variants[0]!.gbp.price
    }
    if (currencyCode === 'SEK') {
      return product.variants[0]!.sek.price
    }
    if (currencyCode === 'EUR') {
      return product.variants[0]!.eur.price
    }
    return product.variants[0]!.usd.price
  }

  private applyBillingIntervalFactor = (
    amount: string,
    sellingPlan: PersistedSellingPlan | null,
  ) => {
    if (!sellingPlan) return amount
    _assert(
      sellingPlan.billing_interval === sellingPlan.delivery_interval,
      'Different intervals unsupported',
    )
    const factor = sellingPlan.billing_interval_count / sellingPlan.delivery_interval_count
    return (parseFloat(amount) * factor).toFixed(2)
  }

  applyPriceModifiers = (product: ShopifyProduct, amount: string, sellingPlanId?: string) => {
    const discountedAmount = this.applySellingPlanDiscount(product, amount, sellingPlanId)
    return this.applyBillingIntervalFactor(
      discountedAmount,
      product.sellingPlans?.find(sp => sp.selling_plan_id === sellingPlanId) || null,
    )
  }

  findPrice(
    product: ShopifyProduct,
    currencyCode: string,
    handle?: string,
    sellingPlanId?: string,
  ): ShopifyPrice['price'] {
    const url = handle || (typeof window !== 'undefined' && window.location.href)
    if (!url) {
      const price = this.getProductPrice(product, currencyCode)
      return {
        ...price,
        amount: this.applyPriceModifiers(product, price.amount, sellingPlanId),
      }
    }

    const parsed = url.split('?')[0]!
    const parts = parsed.split('/')
    const shopIndex = parts.indexOf('shop')
    const locationIndex = shopIndex + 1
    const urlLocation = parts[locationIndex]
    let price: ShopifyPrice['price']
    if (urlLocation === 'us') {
      price = product.variants[0]!.usd.price
    } else if (urlLocation === 'uk') {
      price = product.variants[0]!.gbp.price
    } else if (urlLocation === 'se') {
      price = product.variants[0]!.sek.price
    } else if (urlLocation === 'eu') {
      price = product.variants[0]!.eur.price
    } else if (urlLocation === 'row') {
      price = product.variants[0]!.usd.price
    } else {
      // if we don't have a location, we base the price on the currency code
      // shouldn't happen though
      price = this.getProductPrice(product, currencyCode)
    }
    return {
      ...price,
      amount: this.applyPriceModifiers(product, price.amount, sellingPlanId),
    }
  }
}

export const shopifyService = new ShopifyService()
