import { localTime } from '@naturalcycles/js-lib'
import { Experiment } from '@naturalcycles/shared'
import { SupportedLocale } from '@src/cnst/translations.cnst'
import { BannerDiscountCode } from '@src/components/banner-discount-code/BannerDiscountCode.component'
import { HeaderStandard } from '@src/components/header-standard/HeaderStandard.component'
import { MobileMenu } from '@src/components/mobile-menu/MobileMenu.component'
import languageContext from '@src/context/language.context'
import qaContext from '@src/context/qa.context'
import { getLayout } from '@src/helpers/layout'
import { redDotUnlockSequence } from '@src/helpers/redDot'
import { useAppSelector } from '@src/hooks/useAppSelector'
import { useBFCM } from '@src/hooks/useBFCM'
import { Breakpoints, useBreakpoint } from '@src/hooks/useBreakpoint'
import { PageTypes, usePageType } from '@src/hooks/usePageType'
import { useTestStatus } from '@src/hooks/useTestStatus'
import BannerShop from '@src/shop/components/banner-shop/BannerShop.component'
import HeaderShop from '@src/shop/components/header-shop/HeaderShop.component'
import { MobileMenuStatuses, selectMobileMenuStatus } from '@src/store/mobileMenu/mobileMenu.slice'
import { MobileMenu as NewMobileMenu } from '@src/test280/mobile-menu-v2/MobileMenu.component'
import classNames from 'classnames'
import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { ClientOnly } from '../client-only/ClientOnly.component'
import { TimerBanner } from '../timer-banner/TimerBanner.component'
import styles from './Header.module.scss'

export enum HeaderLayouts {
  StandardHome = 'StandardHome',
  Standard = 'Standard',
  Shop = 'Shop',
  ShopHome = 'ShopHome',
  ShopHomeLocationIndicator = 'ShopHomeLocationIndicator',
  StandardHomeDiscountBanner = 'StandardHomeDiscountBanner',
  StandardDiscountBanner = 'StandardDiscountBanner',
  ShopLocationIndicator = 'ShopLocationIndicator',
}

export enum HeaderPositions {
  Absolute = 'Absolute',
  Sticky = 'Sticky',
}

export enum HeaderThemes {
  TransparentSupportPurple = 'TransparentSupportPurple',
  SupportPurple = 'SupportPurple',
  Blue = 'Blue',
  White = 'White',
  ShopPurple = 'ShopPurple',
}

const standard = [
  HeaderLayouts.StandardHome,
  HeaderLayouts.Standard,
  HeaderLayouts.StandardHomeDiscountBanner,
  HeaderLayouts.StandardDiscountBanner,
]
const shop = [
  HeaderLayouts.Shop,
  HeaderLayouts.ShopLocationIndicator,
  HeaderLayouts.ShopHome,
  HeaderLayouts.ShopHomeLocationIndicator,
]

export interface HeaderProps {
  // Specifically added for DynamicTemplate, which has banner that are managed through Dato
  canHaveDiscountBanner?: boolean
}

export const Header = (props: HeaderProps): React.ReactElement => {
  const { canHaveDiscountBanner = true } = props
  const mobileMenuStatus = useAppSelector(selectMobileMenuStatus)
  const discountCode = useAppSelector(state => state.discountCode.code)
  const pageType = usePageType()
  const position = HeaderPositions.Sticky
  const [code, setCode] = useState('')
  const { toggleRedDot } = useContext(qaContext)
  const sequenceState = useState(0)
  const isMobile = useBreakpoint().width < Breakpoints.bpLarge
  const { locale } = React.useContext(languageContext)
  const [AB280] = useTestStatus(Experiment.NCWEB_NAVIGATION, false, true)
  // This check is to make sure if user is assigned by ABBA to test by browser language,
  // and they choose to navigate to a different locale they will not see the menu in English
  // small chance of this happening but just in case
  const isTest280 = AB280 === 'test' && locale === SupportedLocale.enUS

  // Determine if a discount code is present and if they saved their region
  useEffect(() => {
    setCode(discountCode || sessionStorage.getItem('discountCode') || '')
  }, [discountCode])

  const {
    isBlackFriday,
    code: timerBannerCode,
    headingLarge,
    headingSmall,
    buttonProps: { url: bfcmUrl, text: bfcmButtonText },
  } = useBFCM()

  const layout = getLayout(pageType, code || timerBannerCode || '')
  // Flip this switch if you want the non-sticky header back for the homepage
  // if (pageType === PageTypes.StandardHome && mobileMenuStatus === MobileMenuStatuses.Closed) {
  //   position = HeaderPositions.Absolute
  // }

  const className = classNames(
    styles.Header,
    styles[`Header__layout${layout}`],
    styles[`Header__position${position}`],
    {
      [styles.Header__hideBanners]: mobileMenuStatus === MobileMenuStatuses.Open,
    },
  )

  if ([PageTypes.Campaign, PageTypes.WebView].includes(pageType)) {
    return <></>
  }

  const showDiscountBanner = standard.includes(layout) && canHaveDiscountBanner

  return (
    <>
      <header
        uid="site-header"
        onTouchStart={e => redDotUnlockSequence(e, sequenceState, toggleRedDot)}
        className={className}
      >
        <Helmet>
          <body data-header-layout={layout} />
        </Helmet>

        <ClientOnly>
          {[HeaderLayouts.ShopHome, HeaderLayouts.ShopHomeLocationIndicator].includes(layout) &&
            !isMobile && <BannerShop />}

          {standard.includes(layout) && (
            <HeaderStandard layout={layout} headerTheme="white" test280={isTest280} />
          )}

          {shop.includes(layout) && <HeaderShop />}

          {showDiscountBanner && <BannerDiscountCode />}

          {timerBannerCode && canHaveDiscountBanner && !code && (
            <TimerBanner
              headingLarge={headingLarge}
              headingSmall={headingSmall}
              button={{
                text: bfcmButtonText,
                url: bfcmUrl,
              }}
              expiration={
                isBlackFriday
                  ? localTime.parse('2024-11-30T23:59:59')
                  : localTime.parse('2024-12-03T23:59:59')
              }
            />
          )}

          {[HeaderLayouts.ShopHome, HeaderLayouts.ShopHomeLocationIndicator].includes(layout) &&
            isMobile && <BannerShop />}
        </ClientOnly>

        {isTest280 ? <NewMobileMenu /> : <MobileMenu />}
      </header>
    </>
  )
}
