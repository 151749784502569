import { brandPurple } from '@src/cnst/colors.cnst'
import LanguageContext from '@src/context/language.context'
import { useLocalePrefix } from '@src/hooks/useLocalePrefix'
import React from 'react'
import { NcLink } from '../nc-link/NcLink.component'

interface LogoProps {
  uid: string
  color?: string
  link?: boolean
  href?: string
  isTest280?: boolean
}

const Logo = ({
  color = brandPurple,
  link = true,
  href,
  uid,
  isTest280,
}: LogoProps): React.ReactElement => {
  const { locale } = React.useContext(LanguageContext)
  const localizedHomeUrl = locale === 'en-US' ? useLocalePrefix('/') : useLocalePrefix('')

  const svg = (
    <svg uid={!link ? uid : ''} width="100%" viewBox={`0 0 237 32`} role="link">
      <title>Natural Cycles Logo</title>
      <path
        d="M158.631 7.71694C158.78 7.19552 159.063 6.97205 159.688 6.97205H163.487C164.009 6.97205 164.381 7.49347 164.202 8.04469L156.381 30.5551C156.247 30.9276 155.979 31.1361 155.577 31.1361H152.091C151.45 31.1361 151.092 30.5998 151.301 30.0486L153.878 23.8661H152.686C152.254 23.8661 151.971 23.6426 151.837 23.2255L146.623 8.19367C146.37 7.46368 146.772 6.95715 147.428 6.95715H151.122C151.644 6.95715 151.986 7.07634 152.165 7.65735L155.443 19.2627L158.631 7.71694Z"
        fill={color}
      />
      <path
        d="M144.969 7.98505C144.835 8.02975 141.781 9.05769 141.781 9.05769C141.409 9.17687 141.051 9.01299 140.873 8.70014C139.636 6.42079 137.655 4.94592 134.779 4.94592C130.787 4.94592 127.807 8.23831 127.807 12.2011C127.807 16.1639 130.846 19.4116 134.779 19.4116C137.774 19.4116 139.815 17.892 140.962 15.7617C141.26 15.1955 141.588 15.0615 142.198 15.2998L145.267 16.4767C145.461 16.5512 145.625 16.7002 145.699 16.9088C145.774 17.1024 145.774 17.3259 145.685 17.5047C143.658 21.7654 139.562 24.4023 134.779 24.4023C128.165 24.4023 122.802 18.9349 122.802 12.2011C122.802 5.46734 128.18 -0.00012207 134.779 -0.00012207C139.338 -0.00012207 143.405 2.54738 145.476 6.67405C145.774 7.29976 145.536 7.79138 144.969 7.98505Z"
        fill={color}
      />
      <path
        d="M86.4812 6.58472C85.0213 6.58472 83.2931 7.56797 82.2354 8.53632L81.7587 8.98325V7.82123C81.7587 7.13593 81.3415 6.97206 80.7903 6.97206H77.6469C77.1404 6.97206 76.8126 7.32961 76.8126 7.82123V23.2255C76.8126 23.7618 77.1404 24.0895 77.632 24.0895H80.9244C81.416 24.0895 81.7438 23.6873 81.7438 23.2553V16.5662C81.7438 13.4973 83.2931 11.7989 86.3472 11.7989C86.958 11.7989 87.2559 11.4712 87.2559 11.0093V7.40409C87.2708 6.85288 86.9431 6.58472 86.4812 6.58472Z"
        fill={color}
      />
      <path
        d="M30.4955 6.65918C25.9368 6.65918 22.2124 10.6369 22.2124 15.5382C22.2124 20.4247 25.9219 24.4173 30.4955 24.4173C32.2088 24.4173 33.8028 23.8511 35.0989 22.7636L35.5608 22.3763V23.3595C35.5608 23.7618 35.8885 24.0895 36.2907 24.0895H39.6278C40.1045 24.0895 40.4919 23.7022 40.4919 23.2254V7.88079C40.4919 7.34448 40.2237 6.97203 39.6129 6.97203H36.5142C35.8438 6.97203 35.5608 7.26999 35.5608 7.8659V8.71507C34.4881 7.67223 32.76 6.65918 30.4955 6.65918ZM31.5384 19.9628C29.0505 19.9628 27.3223 18.1453 27.3223 15.5382C27.3223 12.9758 29.0951 11.1136 31.5384 11.1136C34.0263 11.1136 35.9183 13.0205 35.9183 15.5382C35.9034 18.0112 33.9816 19.9628 31.5384 19.9628Z"
        fill={color}
      />
      <path
        d="M96.1797 6.65918C91.621 6.65918 87.8966 10.6369 87.8966 15.5382C87.8966 20.4247 91.6061 24.4173 96.1797 24.4173C97.893 24.4173 99.487 23.8511 100.783 22.7636L101.245 22.3763V23.3148C101.245 23.7469 101.647 24.1044 102.035 24.1044H105.431C105.848 24.1044 106.176 23.7618 106.176 23.3595V7.71692C106.176 7.31468 105.848 6.98693 105.446 6.98693H102.02C101.558 6.98693 101.245 7.28488 101.245 7.77651V8.74486C100.172 7.67222 98.4442 6.65918 96.1797 6.65918ZM97.2226 19.9628C94.7347 19.9628 93.0065 18.1453 93.0065 15.5382C93.0065 12.9758 94.7793 11.1136 97.2226 11.1136C99.7105 11.1136 101.603 13.0205 101.603 15.5382C101.588 18.0112 99.6658 19.9628 97.2226 19.9628Z"
        fill={color}
      />
      <path
        d="M69.2 22.2571V23.4191C69.2 23.7916 69.5128 24.1044 69.8853 24.1044H73.1181C73.6693 24.1044 74.0119 23.8363 74.0119 23.2255V7.80632C74.0119 7.2849 73.6544 6.97205 73.1032 6.97205H70.1832C69.5128 6.97205 69.2 7.2849 69.2 8.01489V15.7915C69.2 19.0243 67.5165 20.2161 65.5202 20.2161C63.1515 20.2161 61.9001 18.6965 61.9001 15.8064L61.8554 7.86591C61.8554 7.34449 61.5872 6.98694 60.9466 6.98694H57.6989C57.0434 6.98694 56.7604 7.2998 56.7604 7.9106V15.5382C56.7604 20.961 59.6952 24.3428 64.6711 24.3428C66.9057 24.3428 68.47 23.3893 69.1553 22.2571C69.1702 22.2124 69.2 22.2273 69.2 22.2571Z"
        fill={color}
      />
      <path
        d="M4.91625 9.83268C4.91625 9.80288 4.94604 9.78798 4.97584 9.81778L14.4061 23.7024C14.5849 23.9557 14.8381 24.1047 15.1808 24.1047H19.5756C20.1566 24.1047 20.4546 23.7471 20.4546 23.2406V1.34098C20.4546 0.864254 20.1417 0.521606 19.6203 0.521606H16.3726C15.881 0.521606 15.5383 0.87915 15.5383 1.46016V16.8048C15.5383 16.8346 15.4936 16.8495 15.4787 16.8197L5.31849 0.923842C5.15461 0.670581 4.88645 0.521606 4.5885 0.521606H0.953455C0.26816 0.521606 0 0.834457 0 1.46016V23.181C0 23.6876 0.32775 24.0898 0.864068 24.0898H4.05218C4.60339 24.0898 4.94604 23.7322 4.94604 23.181V9.83268H4.91625Z"
        fill={color}
      />
      <path
        d="M172.948 24.3726C168.195 24.3726 164.277 20.4693 164.203 15.6723C164.158 13.2886 165.112 10.9497 166.78 9.25137C168.404 7.59773 170.534 6.68896 172.784 6.68896C176.419 6.68896 179.398 8.49158 180.978 11.6201C181.052 11.7542 181.052 11.9181 180.993 12.067C180.933 12.216 180.814 12.3203 180.665 12.3799L177.209 13.5419C177.134 13.5717 177.06 13.5717 177 13.5717C176.776 13.5717 176.553 13.4525 176.434 13.2589C175.6 12.0223 174.467 11.3966 173.052 11.3966C170.773 11.3966 168.985 13.1993 168.985 15.5084C168.985 17.8027 170.802 19.65 173.052 19.65C174.467 19.65 175.644 19.0094 176.434 17.7878C176.568 17.5792 176.747 17.4749 176.985 17.4749C177.134 17.4749 177.298 17.5047 177.492 17.5792L180.337 18.5475C180.605 18.622 180.799 18.8008 180.903 19.0243C180.993 19.2179 180.978 19.4414 180.873 19.6351C179.25 22.6146 176.285 24.3726 172.948 24.3726Z"
        fill={color}
      />
      <path
        d="M115.755 24.0895C116.292 24.0895 116.59 23.732 116.59 23.2403V19.9479C116.59 19.3967 116.321 19.0988 115.621 19.0839H114.653C114.072 19.0839 113.744 18.7561 113.744 18.1453V1.47478C113.744 0.983159 113.461 0.610718 112.88 0.610718H109.677C109.021 0.610718 108.724 0.893772 108.724 1.47478V18.7561C108.724 22.5401 110.109 24.0895 113.476 24.0895H115.755Z"
        fill={color}
      />
      <path
        d="M207.361 20.0523C207.793 20.3949 207.778 20.9163 207.331 21.4229C205.633 23.3745 203.756 24.4024 200.002 24.4024C195.145 24.4024 191.182 20.4247 191.182 15.5234C191.182 10.6369 195.13 6.58473 200.002 6.64433C205.365 6.71881 208.791 10.6965 208.791 15.7319C208.791 16.3278 208.478 16.6258 207.883 16.6258H196.143C196.277 18.5774 197.812 20.0523 200.002 20.0523C202.058 20.0523 203.115 19.0988 203.816 18.3539C204.203 17.9219 204.65 17.9219 205.052 18.2347L207.361 20.0523ZM200.166 10.5326C198.154 10.5326 196.724 11.6946 196.426 13.3334H203.741C203.413 11.7691 202.087 10.5326 200.166 10.5326Z"
        fill={color}
      />
      <path
        d="M190.05 24.0895C190.586 24.0895 190.884 23.732 190.884 23.2403V19.9479C190.884 19.3967 190.616 19.0988 189.916 19.0839H188.948C188.367 19.0839 188.039 18.7561 188.039 18.1453V1.47478C188.039 0.983159 187.756 0.610718 187.175 0.610718H183.972C183.316 0.610718 183.018 0.893772 183.018 1.47478V18.7561C183.018 22.5401 184.404 24.0895 187.771 24.0895H190.05Z"
        fill={color}
      />
      <path
        d="M55.5983 22.5551C55.0321 23.0616 53.8403 24.2683 50.4883 24.2683C48.0153 24.2683 44.8868 22.5253 44.9166 17.7729V11.4116C44.9166 11.3371 44.857 11.2775 44.7825 11.2775H43.4417C42.8905 11.2775 42.5181 10.9647 42.5181 10.3836L42.533 7.80634C42.533 7.35941 42.7862 6.97207 43.4566 6.97207H44.7974C44.8719 6.97207 44.9315 6.91248 44.9315 6.83799V1.41523C44.9315 0.834214 45.2443 0.521362 45.8254 0.521362H49.1029C49.6243 0.521362 49.8775 0.774621 49.8775 1.29604V6.83799C49.8775 6.91248 49.9371 6.97207 50.0116 6.97207H54.0191C54.6001 6.97207 54.9279 7.29982 54.9279 7.89573L54.913 10.339C54.913 10.9349 54.5852 11.2626 54.0042 11.2626H50.0116C49.9371 11.2626 49.8775 11.3222 49.8775 11.3967V17.4303C49.8775 18.8158 50.6671 19.6649 51.9483 19.6649C52.4399 19.6649 52.8124 19.5606 53.1252 19.3223C53.4977 19.0392 53.9893 19.069 54.3022 19.4415C54.7491 19.9927 55.8068 21.3931 55.8068 21.3931C56.0452 21.7804 55.9558 22.2273 55.5983 22.5551Z"
        fill={color}
      />
      <path
        d="M209.968 20.0523C209.522 20.395 209.596 20.9015 209.79 21.1846C210.415 22.1231 212.531 24.4174 217 24.4174C222.05 24.4174 224.583 21.7656 224.583 19.084C224.583 15.8363 222.617 14.2869 217.477 13.0355C215.406 12.529 214.9 12.1268 214.9 11.65C214.9 11.1435 215.391 10.6668 216.613 10.6668C218.237 10.6668 219.414 11.0988 221.276 12.1715C221.693 12.4098 222.125 12.4396 222.483 11.9629L223.957 10.0411C224.315 9.53456 224.091 9.08763 223.674 8.78967C221.782 7.46378 219.697 6.67419 217.015 6.67419C212.769 6.67419 210.058 9.11742 210.058 12.1268C210.058 14.9275 211.89 16.6408 216.002 17.5942C218.415 18.1603 219.548 18.3987 219.548 19.1287C219.548 19.7395 218.892 20.3205 217.119 20.3205C215.347 20.3205 213.842 19.2777 213.157 18.5477C212.829 18.1901 212.352 18.1603 211.935 18.4881C211.92 18.4881 210.117 19.9332 209.968 20.0523Z"
        fill={color}
      />
      <path
        d="M230.767 0C227.354 0 224.583 2.77139 224.583 6.18348C224.583 9.59558 227.354 12.367 230.767 12.367C234.179 12.367 236.95 9.59558 236.95 6.18348C236.95 2.77139 234.179 0 230.767 0ZM230.767 10.0128C228.651 10.0128 226.922 8.29928 226.922 6.16858C226.922 4.05279 228.636 2.3244 230.767 2.3244C232.882 2.3244 234.611 4.03789 234.611 6.16858C234.611 8.29928 232.882 10.0128 230.767 10.0128Z"
        fill={color}
      />
    </svg>
  )

  const svgTest280 = (
    <svg
      uid={!link ? uid : ''}
      width="150"
      height="23"
      viewBox="0 0 150 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M100.42 6.87109C100.515 6.53047 100.694 6.38449 101.09 6.38449H103.495C103.825 6.38449 104.061 6.72511 103.947 7.0852L98.9962 21.7903C98.9114 22.0336 98.7416 22.1699 98.487 22.1699H96.2801C95.8746 22.1699 95.6483 21.8195 95.7803 21.4594L97.4118 17.4206H96.6574C96.3839 17.4206 96.2047 17.2747 96.1198 17.0022L92.819 7.18252C92.6587 6.70565 92.9133 6.37476 93.3283 6.37476H95.6671C95.9972 6.37476 96.2141 6.45261 96.3273 6.83216L98.4021 14.4134L100.42 6.87109Z"
        fill="#72035D"
      />
      <path
        d="M91.7725 7.04622C91.6876 7.07541 89.7543 7.74692 89.7543 7.74692C89.5185 7.82478 89.2921 7.71773 89.179 7.51336C88.3962 6.02435 87.1419 5.06088 85.3217 5.06088C82.7942 5.06088 80.908 7.21166 80.908 9.80039C80.908 12.3891 82.832 14.5107 85.3217 14.5107C87.2173 14.5107 88.5094 13.518 89.2356 12.1264C89.4242 11.7565 89.6317 11.6689 90.0183 11.8247L91.9611 12.5935C92.0837 12.6422 92.1874 12.7395 92.2346 12.8757C92.2818 13.0022 92.2818 13.1482 92.2252 13.265C90.9426 16.0484 88.3491 17.7709 85.3217 17.7709C81.1344 17.7709 77.7393 14.1993 77.7393 9.80039C77.7393 5.4015 81.1438 1.82983 85.3217 1.82983C88.2076 1.82983 90.7822 3.49401 92.0931 6.1898C92.2818 6.59854 92.1309 6.9197 91.7725 7.04622Z"
        fill="#72035D"
      />
      <path
        d="M54.7466 6.13147C53.8224 6.13147 52.7284 6.77379 52.0588 7.40637L51.757 7.69833V6.93923C51.757 6.49156 51.493 6.3845 51.144 6.3845H49.1541C48.8335 6.3845 48.626 6.61807 48.626 6.93923V17.0022C48.626 17.3525 48.8335 17.5666 49.1447 17.5666H51.2289C51.5401 17.5666 51.7476 17.3039 51.7476 17.0216V12.6519C51.7476 10.6471 52.7284 9.53769 54.6618 9.53769C55.0484 9.53769 55.2371 9.32359 55.2371 9.02189V6.66673C55.2465 6.30665 55.039 6.13147 54.7466 6.13147Z"
        fill="#72035D"
      />
      <path
        d="M19.3051 6.18005C16.4193 6.18005 14.0615 8.77852 14.0615 11.9804C14.0615 15.1725 16.4098 17.7807 19.3051 17.7807C20.3897 17.7807 21.3988 17.4109 22.2193 16.7004L22.5116 16.4474V17.0897C22.5116 17.3525 22.7191 17.5666 22.9737 17.5666H25.0863C25.3881 17.5666 25.6333 17.3135 25.6333 17.0021V6.97808C25.6333 6.62773 25.4635 6.38443 25.0768 6.38443H23.1152C22.6908 6.38443 22.5116 6.57907 22.5116 6.96835V7.52308C21.8326 6.84184 20.7386 6.18005 19.3051 6.18005ZM19.9653 14.8708C18.3903 14.8708 17.2963 13.6835 17.2963 11.9804C17.2963 10.3065 18.4186 9.08994 19.9653 9.08994C21.5402 9.08994 22.738 10.3356 22.738 11.9804C22.7285 13.5959 21.512 14.8708 19.9653 14.8708Z"
        fill="#72035D"
      />
      <path
        d="M60.8862 6.18005C58.0003 6.18005 55.6426 8.77852 55.6426 11.9804C55.6426 15.1725 57.9909 17.7807 60.8862 17.7807C61.9707 17.7807 62.9798 17.4109 63.8003 16.7004L64.0927 16.4474V17.0605C64.0927 17.3427 64.3473 17.5763 64.5925 17.5763H66.7428C67.0068 17.5763 67.2143 17.3525 67.2143 17.0897V6.87103C67.2143 6.60826 67.0068 6.39416 66.7522 6.39416H64.5831C64.2907 6.39416 64.0927 6.5888 64.0927 6.90996V7.54254C63.4137 6.84183 62.3197 6.18005 60.8862 6.18005ZM61.5463 14.8708C59.9714 14.8708 58.8774 13.6835 58.8774 11.9804C58.8774 10.3065 59.9997 9.08994 61.5463 9.08994C63.1213 9.08994 64.319 10.3356 64.319 11.9804C64.3096 13.5959 63.093 14.8708 61.5463 14.8708Z"
        fill="#72035D"
      />
      <path
        d="M43.8065 16.3696V17.1287C43.8065 17.372 44.0045 17.5764 44.2403 17.5764H46.2868C46.6357 17.5764 46.8527 17.4012 46.8527 17.0022V6.92952C46.8527 6.5889 46.6263 6.38452 46.2774 6.38452H44.4289C44.0045 6.38452 43.8065 6.58889 43.8065 7.06577V12.1459C43.8065 14.2578 42.7408 15.0363 41.477 15.0363C39.9775 15.0363 39.1853 14.0437 39.1853 12.1556L39.157 6.96845C39.157 6.62782 38.9873 6.39425 38.5817 6.39425H36.5258C36.1108 6.39425 35.9316 6.59863 35.9316 6.99764V11.9805C35.9316 15.5229 37.7895 17.7321 40.9395 17.7321C42.3541 17.7321 43.3443 17.1093 43.7782 16.3696C43.7876 16.3404 43.8065 16.3502 43.8065 16.3696Z"
        fill="#72035D"
      />
      <path
        d="M3.1122 8.25319C3.1122 8.23373 3.13107 8.22399 3.14993 8.24346L9.1197 17.3137C9.23287 17.4792 9.3932 17.5765 9.61011 17.5765H12.3922C12.76 17.5765 12.9487 17.3429 12.9487 17.012V2.70592C12.9487 2.39449 12.7506 2.17065 12.4205 2.17065H10.3646C10.0534 2.17065 9.83645 2.40422 9.83645 2.78377V12.8078C9.83645 12.8273 9.80816 12.837 9.79873 12.8175L3.36684 2.43342C3.2631 2.26797 3.09334 2.17065 2.90472 2.17065H0.60358C0.169757 2.17065 0 2.37503 0 2.78377V16.9731C0 17.304 0.207481 17.5668 0.546994 17.5668H2.56521C2.91416 17.5668 3.13107 17.3332 3.13107 16.9731V8.25319H3.1122Z"
        fill="#72035D"
      />
      <path
        d="M109.484 17.7515C106.476 17.7515 103.995 15.2017 103.948 12.068C103.92 10.5109 104.524 8.98295 105.58 7.8735C106.608 6.79324 107.956 6.19958 109.38 6.19958C111.682 6.19958 113.568 7.37716 114.567 9.42089C114.615 9.50848 114.615 9.61553 114.577 9.71285C114.539 9.81018 114.464 9.8783 114.369 9.91723L112.181 10.6763C112.134 10.6958 112.087 10.6958 112.049 10.6958C111.908 10.6958 111.766 10.6179 111.691 10.4914C111.163 9.68366 110.446 9.27491 109.55 9.27491C108.107 9.27491 106.976 10.4525 106.976 11.961C106.976 13.4597 108.126 14.6665 109.55 14.6665C110.446 14.6665 111.191 14.248 111.691 13.45C111.776 13.3137 111.889 13.2456 112.04 13.2456C112.134 13.2456 112.238 13.2651 112.361 13.3137L114.162 13.9463C114.332 13.995 114.454 14.1117 114.52 14.2577C114.577 14.3842 114.567 14.5302 114.501 14.6567C113.473 16.6032 111.597 17.7515 109.484 17.7515Z"
        fill="#72035D"
      />
      <path
        d="M73.2785 17.5666C73.6181 17.5666 73.8067 17.333 73.8067 17.0119V14.8611C73.8067 14.501 73.6369 14.3064 73.1937 14.2966H72.5807C72.2128 14.2966 72.0054 14.0825 72.0054 13.6835V2.79334C72.0054 2.47218 71.8262 2.22888 71.4584 2.22888H69.4307C69.0158 2.22888 68.8271 2.41379 68.8271 2.79334V14.0825C68.8271 16.5545 69.7042 17.5666 71.8356 17.5666H73.2785Z"
        fill="#72035D"
      />
      <path
        d="M131.269 14.9292C131.543 15.153 131.533 15.4937 131.25 15.8246C130.175 17.0995 128.987 17.771 126.61 17.771C123.536 17.771 121.027 15.1725 121.027 11.9707C121.027 8.77854 123.527 6.13142 126.61 6.17035C130.006 6.21901 132.175 8.81747 132.175 12.1069C132.175 12.4962 131.977 12.6908 131.599 12.6908H124.168C124.253 13.9657 125.224 14.9292 126.61 14.9292C127.912 14.9292 128.582 14.3063 129.025 13.8197C129.27 13.5375 129.553 13.5375 129.808 13.7419L131.269 14.9292ZM126.714 8.71041C125.441 8.71041 124.536 9.46951 124.347 10.54H128.978C128.77 9.51817 127.931 8.71041 126.714 8.71041Z"
        fill="#72035D"
      />
      <path
        d="M120.31 17.5666C120.649 17.5666 120.838 17.333 120.838 17.0119V14.8611C120.838 14.501 120.668 14.3064 120.225 14.2966H119.612C119.244 14.2966 119.037 14.0825 119.037 13.6835V2.79334C119.037 2.47218 118.857 2.22888 118.49 2.22888H116.462C116.047 2.22888 115.858 2.41379 115.858 2.79334V14.0825C115.858 16.5545 116.735 17.5666 118.867 17.5666H120.31Z"
        fill="#72035D"
      />
      <path
        d="M35.1964 16.5642C34.838 16.8951 34.0835 17.6834 31.9616 17.6834C30.396 17.6834 28.4155 16.5448 28.4344 13.4403V9.28467C28.4344 9.23601 28.3967 9.19708 28.3495 9.19708H27.5007C27.1518 9.19708 26.916 8.9927 26.916 8.61315L26.9254 6.92951C26.9254 6.63755 27.0858 6.38451 27.5102 6.38451H28.3589C28.4061 6.38451 28.4438 6.34558 28.4438 6.29692V2.75446C28.4438 2.37491 28.6419 2.17053 29.0097 2.17053H31.0845C31.4146 2.17053 31.5749 2.33598 31.5749 2.6766V6.29692C31.5749 6.34558 31.6126 6.38451 31.6598 6.38451H34.1967C34.5645 6.38451 34.772 6.59862 34.772 6.9879L34.7625 8.58396C34.7625 8.97324 34.5551 9.18735 34.1873 9.18735H31.6598C31.6126 9.18735 31.5749 9.22627 31.5749 9.27493V13.2164C31.5749 14.1215 32.0747 14.6762 32.8858 14.6762C33.197 14.6762 33.4328 14.6081 33.6308 14.4524C33.8666 14.2675 34.1778 14.2869 34.3759 14.5302C34.6588 14.8903 35.3284 15.8051 35.3284 15.8051C35.4793 16.0582 35.4227 16.3501 35.1964 16.5642Z"
        fill="#72035D"
      />
      <path
        d="M132.92 14.9292C132.637 15.153 132.684 15.4839 132.806 15.6688C133.203 16.282 134.542 17.7807 137.371 17.7807C140.568 17.7807 142.171 16.0484 142.171 14.2966C142.171 12.175 140.927 11.1629 137.673 10.3454C136.362 10.0145 136.041 9.75175 136.041 9.44033C136.041 9.10944 136.353 8.79801 137.126 8.79801C138.154 8.79801 138.899 9.08024 140.078 9.78095C140.342 9.93666 140.615 9.95613 140.842 9.6447L141.775 8.38927C142.002 8.05838 141.86 7.76641 141.596 7.57177C140.398 6.70562 139.078 6.18982 137.38 6.18982C134.693 6.18982 132.976 7.78588 132.976 9.75175C132.976 11.5814 134.136 12.7006 136.739 13.3234C138.267 13.6932 138.984 13.849 138.984 14.3258C138.984 14.7248 138.569 15.1044 137.447 15.1044C136.324 15.1044 135.372 14.4231 134.938 13.9463C134.73 13.7127 134.429 13.6932 134.165 13.9073C134.155 13.9073 133.014 14.8514 132.92 14.9292Z"
        fill="#72035D"
      />
      <path
        d="M145.95 0.460083C143.79 0.460083 142.035 2.27052 142.035 4.4995C142.035 6.72848 143.79 8.53891 145.95 8.53891C148.11 8.53891 149.864 6.72848 149.864 4.4995C149.864 2.27052 148.11 0.460083 145.95 0.460083ZM145.95 7.00102C144.61 7.00102 143.516 5.88166 143.516 4.48976C143.516 3.1076 144.601 1.97851 145.95 1.97851C147.289 1.97851 148.383 3.09787 148.383 4.48976C148.383 5.88166 147.289 7.00102 145.95 7.00102Z"
        fill="#72035D"
      />
    </svg>
  )

  if (link) {
    return (
      <NcLink uid={uid} to={href || localizedHomeUrl}>
        {isTest280 ? svgTest280 : svg}
      </NcLink>
    )
  }
  return svg
}

export default Logo
