import { _stringEnumKey } from '@naturalcycles/js-lib'
import { ncNavigate } from '@src/helpers/nc-navigate'
import { useAppDispatch } from '@src/hooks/useAppDispatch'
import { useAppSelector } from '@src/hooks/useAppSelector'
import {
  RegionDescriptor,
  RegionKey,
  regionKeys,
  RegionSlug,
  ShippingLocations,
  StorageRegion,
} from '@src/shop/cnst/shopify.cnst'
import {
  clearCart,
  selectExpectedLocation,
  selectShopifyShippingLocation,
  setExpectedLocation,
  setShippingLocation,
} from '@src/shop/store/shopify.slice'
import { MobileMenuStatuses, selectMobileMenuStatus } from '@src/store/mobileMenu/mobileMenu.slice'
import React, { useMemo, useState } from 'react'
import styles from './RegionIndicator.module.scss'

/**
 * This component is displayed when the user is outside their expected shipping region.
 *
 * That is, if a user is in the US and tries to access the EU shop, this component will be displayed.
 * It allows the user to change their shipping region and continue shopping.
 *
 * The region picker component does the exact same thing, but it is less obtrusive and is displayed in the header. This component
 * is displayed above the header.
 */
const RegionIndicator = (): React.ReactElement => {
  const getTextForShippingLocation = (region?: ShippingLocations): RegionDescriptor => {
    if (!region) return RegionDescriptor.ROW
    const regionKeyOrBlocked = _stringEnumKey(ShippingLocations, region)
    const key = regionKeyOrBlocked === 'Blocked' ? 'ROW' : regionKeyOrBlocked
    return RegionDescriptor[key]
  }

  const regionUidHandler = (region: RegionKey) => {
    const uidPrefix = 'region-option-'
    const uidSuffix = StorageRegion[region]
    return uidPrefix.concat(uidSuffix)
  }

  const shippingLocation = useAppSelector(selectShopifyShippingLocation)
  const expectedLocation = useAppSelector(selectExpectedLocation)

  const region = useMemo(() => {
    return expectedLocation || shippingLocation
  }, [])

  const status = useAppSelector(selectMobileMenuStatus)

  const [expanded, setExpanded] = useState(false)
  const [selectedRegion, setSelectedRegion] = useState(region)
  const [regionText, setRegionText] = useState(getTextForShippingLocation(region))

  const dispatch = useAppDispatch()

  const onClose = (event: React.MouseEvent): void => {
    event.preventDefault()
    if (!selectedRegion) return
    dispatch(setShippingLocation(selectedRegion))
    dispatch(setExpectedLocation(selectedRegion))
    const urlRegion = _stringEnumKey(ShippingLocations, selectedRegion)
    const path = RegionSlug[urlRegion === 'Blocked' ? 'ROW' : urlRegion]
    const url = `/shop/${path}`
    ncNavigate(url)
  }

  const onClickRegionSelect = (newShippingLocation: ShippingLocations) => {
    if (newShippingLocation !== shippingLocation) {
      dispatch(clearCart())
    }
    dispatch(setShippingLocation(newShippingLocation))
    dispatch(setExpectedLocation(newShippingLocation))

    setSelectedRegion(newShippingLocation)
    setRegionText(getTextForShippingLocation(newShippingLocation))
  }

  const toggleRegions = () => {
    setExpanded(!expanded)
  }

  React.useEffect(() => {
    setExpanded(false)
    setSelectedRegion(expectedLocation || shippingLocation)
    setRegionText(getTextForShippingLocation(expectedLocation || shippingLocation))
  }, [shippingLocation, expectedLocation])

  return (
    <div
      className={
        status === MobileMenuStatuses.Open
          ? styles.RegionIndicator__hidden
          : styles.RegionIndicator__background
      }
      uid="region-indicator"
    >
      <div className={styles.RegionIndicator}>
        <div className={styles.RegionIndicator__headerContainer}>
          <p className={styles.RegionIndicator__header}>
            Select your location to browse all available products.
          </p>
          <button
            aria-label="close location indicator"
            className={`${styles.RegionIndicator__closeButton} ${
              window.location.pathname.length > 8
                ? styles.RegionIndicator__closeButtonProductPage
                : ''
            }`}
            onClick={onClose}
          >
            <svg
              aria-hidden={true}
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.3 1.9L9.3 7.9L15.3 13.9L13.9 15.3L7.9 9.3L1.9 15.3L0.5 13.9L6.5 7.9L0.5 1.9L1.9 0.5L7.9 6.5L13.9 0.5L15.3 1.9Z"
                fill="white"
              />
            </svg>
          </button>
        </div>

        <div className={styles.RegionIndicator__secondBarContainer}>
          <div className={styles.RegionIndicator__secondBar}>
            <button
              aria-label="select a new region"
              aria-haspopup="listbox"
              aria-expanded={expanded}
              className={styles.RegionIndicator__selectionInput}
              onClick={toggleRegions}
            >
              <div className={styles.RegionIndicator__inputContainer}>
                <div dangerouslySetInnerHTML={{ __html: regionText }} />
                <svg
                  className={
                    expanded ? styles.RegionIndicator__arrowDown : styles.RegionIndicator__arrowUp
                  }
                  aria-hidden={true}
                  width="10"
                  height="7"
                  viewBox="0 0 10 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.34175 1.28765L4.88641 4.73907L8.37701 1.23418"
                    stroke="#1A1A1A"
                    strokeWidth="2"
                  />
                </svg>
              </div>
            </button>
            <button
              aria-label="continue and close indicator"
              className={styles.RegionIndicator__continueButton}
              onClick={onClose}
            >
              Continue
            </button>
          </div>
          <ul
            role="listbox"
            className={expanded ? styles.RegionIndicator__dropdown : styles.RegionIndicator__hidden}
          >
            {regionKeys.map(region => (
              <li
                key={region}
                role="option"
                aria-selected={selectedRegion === ShippingLocations[region]}
                uid={regionUidHandler(region)}
                className={`${styles.RegionIndicator__item} ${
                  selectedRegion === ShippingLocations[region] &&
                  styles.RegionIndicator__itemSelected
                }`}
              >
                <a
                  href={`/shop/${RegionSlug[region]}`}
                  className={styles.RegionIndicator__link}
                  onClick={e => {
                    e.preventDefault()
                    onClickRegionSelect(ShippingLocations[region])
                    ncNavigate(`/shop/${RegionSlug[region]}`)
                  }}
                  dangerouslySetInnerHTML={{
                    __html: getTextForShippingLocation(ShippingLocations[region]),
                  }}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}
export default RegionIndicator
