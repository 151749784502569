import LanguageContext from '@src/context/language.context'
import { useAppSelector } from '@src/hooks/useAppSelector'
import { selectGeolocationCountry } from '@src/store/geolocation/geolocation.slice'
import { MobileMenuStatuses, selectMobileMenuStatus } from '@src/store/mobileMenu/mobileMenu.slice'
import classNames from 'classnames'
import React from 'react'
import { menu } from '../Menu'
import { MenuLink } from '../menu-link/MenuLink.component'
import styles from './MobileMenu.module.scss'

export const MobileMenu = () => {
  const country = useAppSelector(selectGeolocationCountry)
  const status = useAppSelector(selectMobileMenuStatus)
  const { locale } = React.useContext(LanguageContext)
  const menuRef = React.useRef<HTMLElement>(null)

  React.useEffect(() => {
    if (status === MobileMenuStatuses.Open) {
      // Prevent body from scrolling in the background
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }

    return () => {
      document.body.style.overflow = ''
    }
  }, [status])

  if (status === MobileMenuStatuses.Closed) {
    return null
  }

  // Not all menu items are available in all countries and locales
  const filteredMenuItems = menu.filter(
    item =>
      (!item.region || item.region.includes(country)) &&
      (!item.locale || item.locale.includes(locale)),
  )

  const mainMenuItems = filteredMenuItems.filter(item => item.type !== 'minorMenu')
  const minorMenuItems = filteredMenuItems.filter(item => item.type === 'minorMenu')

  return (
    <nav
      id="menu"
      ref={menuRef}
      aria-modal="true"
      role="dialog"
      className={classNames(styles.BurgerMenu, {
        [styles.BurgerMenu__open]: status === MobileMenuStatuses.Open,
      })}
    >
      <ul className={styles.BurgerMenu__container}>
        {mainMenuItems.map(item => (
          <li key={item.id} className={styles.BurgerMenu__item}>
            {item.type === 'link' ? (
              <MenuLink href={item.href} label={item.text} />
            ) : (
              <React.Fragment key={item.id}> {item.submenu}</React.Fragment>
            )}
          </li>
        ))}

        <hr />

        {minorMenuItems.map(item => (
          <li key={item.id} className={styles.BurgerMenu__minorItem}>
            <a href={item.href}>{item.text}</a>
          </li>
        ))}
      </ul>
    </nav>
  )
}
