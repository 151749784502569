import { _stringEnumKey } from '@naturalcycles/js-lib'
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createStorefrontApiClient } from '@shopify/storefront-api-client'
import { isE2eTest, SHOPIFY_US_DOMAIN } from '@src/helpers/env'
import { ncNavigate } from '@src/helpers/nc-navigate'
import { regionKeys, ShippingCurrency, ShippingLocations } from '@src/shop/cnst/shopify.cnst'
import { mixpanelService } from '@src/srv/mixpanel.service'
import { Thunk } from '@src/store'
import geolocation, { GeolocationCountries } from '@src/store/geolocation/geolocation.slice'
import {
  AddToCartSources,
  CreateCartData,
  CreateCartInput,
  CreateCheckoutData,
  CreateCheckoutInput,
  PersistedSellingPlan,
  ShopifyCart,
  ShopifyLineItem,
  ShopifyProduct,
} from './shopify.model'
import cartCreateMutation from './shopify-gql/cart-create.graphql'
import checkoutCreateMutation from './shopify-gql/checkout-create.graphql'

// List of countries we don't ship webshop products to
export const shippingBlockList: GeolocationCountries[] = [
  GeolocationCountries.AF,
  GeolocationCountries.AO,
  GeolocationCountries.AI,
  GeolocationCountries.AQ,
  GeolocationCountries.AG,
  GeolocationCountries.AR,
  GeolocationCountries.AW,
  GeolocationCountries.AZ,
  GeolocationCountries.BS,
  GeolocationCountries.BH,
  GeolocationCountries.BD,
  GeolocationCountries.BB,
  GeolocationCountries.BZ,
  GeolocationCountries.BJ,
  GeolocationCountries.BM,
  GeolocationCountries.BT,
  GeolocationCountries.BO,
  GeolocationCountries.BQ,
  GeolocationCountries.BW,
  GeolocationCountries.BV,
  GeolocationCountries.IO,
  GeolocationCountries.BN,
  GeolocationCountries.BF,
  GeolocationCountries.BI,
  GeolocationCountries.CM,
  GeolocationCountries.KY,
  GeolocationCountries.CF,
  GeolocationCountries.TD,
  GeolocationCountries.CL,
  GeolocationCountries.CO,
  GeolocationCountries.KM,
  GeolocationCountries.CG,
  GeolocationCountries.CR,
  GeolocationCountries.CI,
  GeolocationCountries.CU,
  GeolocationCountries.CW,
  GeolocationCountries.CD,
  GeolocationCountries.DJ,
  GeolocationCountries.DM,
  GeolocationCountries.DO,
  GeolocationCountries.EC,
  GeolocationCountries.EG,
  GeolocationCountries.SV,
  GeolocationCountries.GQ,
  GeolocationCountries.ER,
  GeolocationCountries.ET,
  GeolocationCountries.FK,
  GeolocationCountries.GF,
  GeolocationCountries.GA,
  GeolocationCountries.GM,
  GeolocationCountries.GH,
  GeolocationCountries.GD,
  GeolocationCountries.GP,
  GeolocationCountries.GT,
  GeolocationCountries.GN,
  GeolocationCountries.GW,
  GeolocationCountries.GY,
  GeolocationCountries.HT,
  GeolocationCountries.HN,
  GeolocationCountries.ID,
  GeolocationCountries.IL,
  GeolocationCountries.IR,
  GeolocationCountries.IQ,
  GeolocationCountries.JM,
  GeolocationCountries.JO,
  GeolocationCountries.KZ,
  GeolocationCountries.KE,
  GeolocationCountries.KI,
  GeolocationCountries.KW,
  GeolocationCountries.KG,
  GeolocationCountries.LS,
  GeolocationCountries.LR,
  GeolocationCountries.LY,
  GeolocationCountries.MG,
  GeolocationCountries.MW,
  GeolocationCountries.ML,
  GeolocationCountries.MH,
  GeolocationCountries.MQ,
  GeolocationCountries.MR,
  GeolocationCountries.MU,
  GeolocationCountries.YT,
  GeolocationCountries.FM,
  GeolocationCountries.MS,
  GeolocationCountries.MA,
  GeolocationCountries.MZ,
  GeolocationCountries.NA,
  GeolocationCountries.NR,
  GeolocationCountries.NI,
  GeolocationCountries.NE,
  GeolocationCountries.NG,
  GeolocationCountries.NU,
  GeolocationCountries.OM,
  GeolocationCountries.PK,
  GeolocationCountries.PW,
  GeolocationCountries.PS,
  GeolocationCountries.PA,
  GeolocationCountries.PY,
  GeolocationCountries.PE,
  GeolocationCountries.PR,
  GeolocationCountries.QA,
  GeolocationCountries.RE,
  GeolocationCountries.RW,
  GeolocationCountries.BL,
  GeolocationCountries.KN,
  GeolocationCountries.LC,
  GeolocationCountries.MF,
  GeolocationCountries.PM,
  GeolocationCountries.VC,
  GeolocationCountries.ST,
  GeolocationCountries.SA,
  GeolocationCountries.SN,
  GeolocationCountries.SL,
  GeolocationCountries.SX,
  GeolocationCountries.SO,
  GeolocationCountries.ZA,
  GeolocationCountries.SS,
  GeolocationCountries.SD,
  GeolocationCountries.SR,
  GeolocationCountries.SZ,
  GeolocationCountries.SY,
  GeolocationCountries.TJ,
  GeolocationCountries.TZ,
  GeolocationCountries.TL,
  GeolocationCountries.TG,
  GeolocationCountries.TK,
  GeolocationCountries.TO,
  GeolocationCountries.TT,
  GeolocationCountries.TN,
  GeolocationCountries.TM,
  GeolocationCountries.TC,
  GeolocationCountries.TV,
  GeolocationCountries.UG,
  GeolocationCountries.AE,
  GeolocationCountries.UY,
  GeolocationCountries.UZ,
  GeolocationCountries.VU,
  GeolocationCountries.VE,
  GeolocationCountries.VN,
  GeolocationCountries.VG,
  GeolocationCountries.VI,
  GeolocationCountries.WF,
  GeolocationCountries.EH,
  GeolocationCountries.YE,
  GeolocationCountries.ZM,
  GeolocationCountries.ZW,
]

const sekCountries: GeolocationCountries[] = [GeolocationCountries.SE]

const gbpCountries: GeolocationCountries[] = [
  GeolocationCountries.FK,
  GeolocationCountries.GB,
  GeolocationCountries.GG,
  GeolocationCountries.GI,
  GeolocationCountries.IM,
  GeolocationCountries.JE,
]

export const eurCountries: GeolocationCountries[] = [
  GeolocationCountries.AD,
  GeolocationCountries.AT,
  GeolocationCountries.AX,
  GeolocationCountries.BE,
  GeolocationCountries.CH,
  GeolocationCountries.CZ,
  GeolocationCountries.CY,
  GeolocationCountries.DE,
  GeolocationCountries.DK,
  GeolocationCountries.EE,
  GeolocationCountries.ES,
  GeolocationCountries.EU,
  GeolocationCountries.FI,
  GeolocationCountries.FO,
  GeolocationCountries.FR,
  GeolocationCountries.GF,
  GeolocationCountries.GR,
  GeolocationCountries.IE,
  GeolocationCountries.IS,
  GeolocationCountries.IT,
  GeolocationCountries.LI,
  GeolocationCountries.LT,
  GeolocationCountries.LU,
  GeolocationCountries.LV,
  GeolocationCountries.MC,
  GeolocationCountries.MT,
  GeolocationCountries.NL,
  GeolocationCountries.NO,
  GeolocationCountries.PL,
  GeolocationCountries.PT,
  GeolocationCountries.RS,
  GeolocationCountries.SI,
  GeolocationCountries.SK,
  GeolocationCountries.SM,
  GeolocationCountries.UA,
  GeolocationCountries.VA,
]

export interface ShopifyState {
  cart: ShopifyCart
  currency: ShippingCurrency
  // we allow these to be undefined until decided, and let users of it handle the undefined case
  shippingLocation?: ShippingLocations
  expectedLocation?: ShippingLocations
  alteredRegion: boolean
  miniCartOpened: boolean
}

const shopifyToken = process.env['GATSBY_SHOPIFY_US_TOKEN']!

const shopifyClient = createStorefrontApiClient({
  storeDomain: SHOPIFY_US_DOMAIN,
  publicAccessToken: shopifyToken,
  // https://shopify.dev/docs/api/usage/versioning
  apiVersion: '2024-04',
})

const initialState: ShopifyState = {
  cart: {},
  currency: ShippingCurrency.ROW,
  shippingLocation: ShippingLocations.ROW,
  alteredRegion: false,
  miniCartOpened: false,
}

// TODO: this can be simplified and made more readable and DRY
const slice = createSlice({
  name: 'shopify',
  initialState,

  reducers: {
    setMiniCartOpened(shopify: ShopifyState, action: PayloadAction<boolean>) {
      shopify.miniCartOpened = action.payload
    },
    addToCart(shopify: ShopifyState, action: PayloadAction<ShopifyLineItem>) {
      const lineItem = action.payload
      if (shopify.cart[lineItem.variantId]) {
        shopify.cart[lineItem.variantId]!.quantity += lineItem.quantity
      } else {
        shopify.cart[lineItem.variantId] = lineItem
      }
    },

    changeLineItemQuantity(shopify: ShopifyState, action: PayloadAction<ShopifyLineItem>) {
      const lineItem = action.payload
      shopify.cart[lineItem.variantId]!.quantity = lineItem.quantity
    },

    changeIsSubscription(shopify: ShopifyState, action: PayloadAction<ShopifyLineItem>) {
      const lineItem = action.payload
      shopify.cart[lineItem.variantId]!.isSubscription = lineItem.isSubscription
    },

    changeLineItemSellingPlan(
      shopify: ShopifyState,
      action: PayloadAction<{
        variantId: string
        sellingPlanId: string | undefined
      }>,
    ) {
      const { variantId, sellingPlanId } = action.payload
      shopify.cart[variantId]!.sellingPlanId = sellingPlanId
    },

    removeFromCart(shopify: ShopifyState, action: PayloadAction<string>) {
      delete shopify.cart[action.payload]
    },

    clearCart(shopify: ShopifyState) {
      shopify.cart = {}
    },

    changeShippingLocation(shopify: ShopifyState, action: PayloadAction<ShippingLocations>) {
      shopify.alteredRegion = true
      const previousLocation = shopify.shippingLocation
      if (previousLocation === action.payload) return
      // Availability of items differ between regions, so we need to clear the cart if the region changes
      sessionStorage.removeItem('cart')
      shopify.shippingLocation = action.payload
      shopify.currency =
        action.payload === ShippingLocations.Blocked
          ? ShippingCurrency.ROW
          : ShippingCurrency[
              _stringEnumKey(ShippingLocations, action.payload) as Exclude<
                keyof typeof ShippingLocations,
                'Blocked'
              >
            ] || ShippingCurrency.ROW
      sessionStorage.setItem('Region:', action.payload)
    },

    setShippingLocationByCountry(
      shopify: ShopifyState,
      action: PayloadAction<GeolocationCountries>,
    ) {
      const country = action.payload
      const shippingLocation = getLocationFromCountry(country)
      shopify.shippingLocation = shippingLocation
      shopify.currency = getCurrencyFromCountry(country)
      shopify.alteredRegion = true
    },

    setExpectedLocationByCountry(
      shopify: ShopifyState,
      action: PayloadAction<GeolocationCountries>,
    ) {
      const location = getLocationFromCountry(action.payload)
      shopify.expectedLocation = location
      if (location) {
        sessionStorage.setItem('ExpectedLocation:', location)
      }
    },

    setExpectedLocation(
      shopify: ShopifyState,
      action: PayloadAction<ShippingLocations | undefined>,
    ) {
      shopify.expectedLocation = action.payload
      if (action.payload) {
        sessionStorage.setItem('ExpectedLocation:', action.payload)
      }
    },

    alteredRegion(shopify: ShopifyState, action: PayloadAction<boolean>) {
      shopify.alteredRegion = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(geolocation.actions.success, state => {
      if (isE2eTest()) {
        state.shippingLocation = ShippingLocations.US
        return
      }
    })
  },
})

function getCurrencyFromCountry(country: GeolocationCountries): ShippingCurrency {
  if (sekCountries.includes(country)) {
    return ShippingCurrency.SE
  }
  if (gbpCountries.includes(country)) {
    return ShippingCurrency.GB
  }
  if (eurCountries.includes(country)) {
    return ShippingCurrency.EU
  }
  return ShippingCurrency.ROW
}

export function getLocationFromCountry(
  country: GeolocationCountries,
): ShippingLocations | undefined {
  let shippingLocation: ShippingLocations | undefined

  regionKeys.forEach(region => {
    if (region === country) {
      shippingLocation = ShippingLocations[region]
      return
    }
    if (region === 'EU' && eurCountries.includes(country)) {
      shippingLocation = ShippingLocations.EU
      return
    }
  })
  if (shippingBlockList.includes(country)) {
    shippingLocation = ShippingLocations.Blocked
  }

  return shippingLocation
}

export const { setMiniCartOpened } = slice.actions

export const addToCart =
  (
    product: ShopifyProduct,
    quantity: number,
    source: AddToCartSources,
    sellingPlanId?: string,
    isSubscription?: boolean,
  ): Thunk =>
  async (dispatch, getState) => {
    const title = product.title
    const variantId = product.variants[0]!.id
    const lineItem: ShopifyLineItem = {
      variantId,
      quantity,
      sellingPlanId: isSubscription ? sellingPlanId : undefined,
      isSubscription,
    }

    dispatch(slice.actions.addToCart(lineItem))
    persistCart(getState().shopify.cart)

    // don't track buy now button cart adds as these have their own mixpanel event
    if (source !== AddToCartSources.BuyNowButton) {
      mixpanelService.track('ShopifyAddToCart', {
        product: title,
        quantity,
        source,
      })
    }
  }

export const changeLineItemQuantity =
  (variantId: string, quantity: number, title: string): Thunk =>
  async (dispatch, getState) => {
    if (quantity === 0) {
      dispatch(removeFromCart(variantId, title))
      return
    }

    const lineItem: ShopifyLineItem = { variantId, quantity }
    dispatch(slice.actions.changeLineItemQuantity(lineItem))
    persistCart(getState().shopify.cart)

    mixpanelService.track('ShopifyChangeLineItemQuantity', {
      product: title,
      quantity,
    })
  }

export const changeIsSubscription =
  (variantId: string, isSubscription: boolean): Thunk =>
  async (dispatch, getState) => {
    const item = getState().shopify.cart[variantId]
    const lineItem: ShopifyLineItem = { ...item!, isSubscription }
    dispatch(slice.actions.changeIsSubscription(lineItem))
    persistCart(getState().shopify.cart)
  }

export const changeLineItemSellingPlan =
  (variantId: string, sellingPlanId: string | undefined): Thunk =>
  async (dispatch, getState) => {
    dispatch(slice.actions.changeLineItemSellingPlan({ variantId, sellingPlanId }))
    persistCart(getState().shopify.cart)
  }

export const removeFromCart =
  (variantId: string, title: string): Thunk =>
  async (dispatch, getState) => {
    dispatch(slice.actions.removeFromCart(variantId))
    persistCart(getState().shopify.cart)

    mixpanelService.track('ShopifyRemoveFromCart', {
      product: title,
    })
  }

export const buyNow =
  (product: ShopifyProduct, quantity: number): Thunk =>
  async dispatch => {
    mixpanelService.trackClick('ShopifyBuySingleProduct', {
      product: product.title,
      quantity,
    })

    dispatch(addToCart(product, quantity, AddToCartSources.BuyNowButton))
    dispatch(checkout())
  }

export const clearCart = (): Thunk => dispatch => {
  sessionStorage.removeItem('cart')
  dispatch(slice.actions.clearCart())
}

const setUsersRegion = (state: ShopifyState): GeolocationCountries => {
  const region = state.shippingLocation
  let country: GeolocationCountries

  if (region === ShippingLocations.EU) {
    country = GeolocationCountries.DE
  } else if (region === ShippingLocations.GB) {
    country = GeolocationCountries.GB
  } else if (region === ShippingLocations.SE) {
    country = GeolocationCountries.SE
  } else {
    country = GeolocationCountries.US
  }
  return country
}

const checkoutWithCreateCart =
  (state: ShopifyState, lineItems: ShopifyLineItem[], geolocation: GeolocationCountries): Thunk =>
  async () => {
    const input: CreateCartInput = {
      buyerIdentity: {
        countryCode:
          !state.alteredRegion && geolocation !== GeolocationCountries.Unknown
            ? geolocation
            : setUsersRegion(state),
      },
      lines: lineItems.map(lineItem => ({
        merchandiseId: lineItem.variantId,
        quantity: lineItem.quantity,
        sellingPlanId: lineItem.sellingPlanId
          ? `gid://shopify/SellingPlan/${lineItem.sellingPlanId}`
          : null,
      })),
    }
    const res = await shopifyClient.request<CreateCartData>(cartCreateMutation, {
      variables: { input },
    })
    if (res.errors) {
      console.error(res.errors.message, res.errors.graphQLErrors)
      return
    }
    const { checkoutUrl } = res.data!.cartCreate.cart
    ncNavigate(checkoutUrl)
  }

/**
 * @deprecated 2025-04-01 We should use checkoutWithCreateCart instead,
 * which also supports subscriptions. However, currency formatting is
 * bugged with that function, so we need to fix that first and only
 * use checkoutWithCreateCart function for subscription purchases.
 */
const checkoutWithCreateCheckout =
  (state: ShopifyState, lineItems: ShopifyLineItem[], geolocation: GeolocationCountries): Thunk =>
  async () => {
    const input: CreateCheckoutInput = {
      buyerIdentity: {
        countryCode:
          !state.alteredRegion && geolocation !== GeolocationCountries.Unknown
            ? geolocation
            : setUsersRegion(state),
      },
      lineItems: lineItems.map(lineItem => ({
        variantId: lineItem.variantId,
        quantity: lineItem.quantity,
      })),
    }
    const res = await shopifyClient.request<CreateCheckoutData>(checkoutCreateMutation, {
      variables: { input },
    })
    if (res.errors) {
      console.error(res.errors.message, res.errors.graphQLErrors)
      return
    }

    const { webUrl } = res.data!.checkoutCreate.checkout
    ncNavigate(webUrl)
  }

export const checkout = (): Thunk => (_dispatch, getState) => {
  const state = getState()
  const products = state.shopify.cart
  const geolocation = state.geolocation.country

  const lineItems: ShopifyLineItem[] = Object.values(products)

  const hasItemWithSellPlan = lineItems.some(item => item.sellingPlanId)

  if (hasItemWithSellPlan) {
    _dispatch(checkoutWithCreateCart(state.shopify, lineItems, geolocation))
  } else {
    _dispatch(checkoutWithCreateCheckout(state.shopify, lineItems, geolocation))
  }
}

export const setShippingLocationByCountry =
  (country: GeolocationCountries): Thunk =>
  dispatch => {
    dispatch(slice.actions.setShippingLocationByCountry(country))
  }

export const setExpectedLocationByCountry =
  (country: GeolocationCountries): Thunk =>
  dispatch => {
    dispatch(slice.actions.setExpectedLocationByCountry(country))
  }

export const setShippingLocation =
  (location: any): Thunk =>
  dispatch => {
    dispatch(slice.actions.changeShippingLocation(location))
  }

export const setExpectedLocation =
  (location?: ShippingLocations): Thunk =>
  dispatch => {
    dispatch(slice.actions.setExpectedLocation(location))
  }

export const setAlteredRegion =
  (alteredRegion: boolean): Thunk =>
  dispatch => {
    dispatch(slice.actions.alteredRegion(alteredRegion))
  }

export const selectShopify = ({ shopify }: { shopify: ShopifyState }): ShopifyState => shopify

export const selectShopifyShippingLocation = createSelector(
  [selectShopify],
  (shippingLocation): ShippingLocations | undefined => {
    return shippingLocation.shippingLocation
  },
)

export const selectExpectedLocation = createSelector(
  [selectShopify],
  (expectedLocation): ShippingLocations | undefined => {
    return expectedLocation.expectedLocation
  },
)

export const selectShopifyCurrency = createSelector(
  [selectShopify],
  (shippingCurrency): ShippingCurrency => {
    return shippingCurrency.currency
  },
)

export const selectMiniCartOpened = createSelector([selectShopify], (shopify): boolean => {
  return shopify.miniCartOpened
})

function persistCart(cart: ShopifyCart): void {
  sessionStorage.setItem('cart', JSON.stringify(cart))
}

export function sellingPlanToDurationStr(sellingplan: PersistedSellingPlan): string {
  return `Every ${sellingplan.delivery_interval_count} ${sellingplan.delivery_interval}${
    sellingplan.delivery_interval_count > 1 ? 's' : ''
  }`
}

export default slice
