import { MenuDropdownProps } from './menu-dropdown/MenuDropdown.component'
import { MenuLibraryProps } from './menu-library/MenuLibrary.component'

export const cyclemattersDesktopData: MenuLibraryProps['desktopInfo'] = {
  heading: 'Cycle Matters',
  context: 'Your carefully curated health library',
  button: { cta: 'Read articles', href: '/cyclematters' },
}

export const cyclematterLibraryData: MenuLibraryProps = {
  label: 'Cycle Matters Library',
  categories: [
    {
      category: 'Birth Control',
      articles: [
        {
          text: 'Switching birth control methods',
          href: '/cyclematters/switching-birth-control-methods',
          thumbnail: '/img/ab280/switching-birth-control-methods.jpg',
          alt: 'Switching birth control methods',
        },
        {
          text: 'Birth control effectiveness',
          href: '/cyclematters/birth-control-effectiveness-explained',
          thumbnail: '/img/ab280/birth-control-effectiveness-explained.jpg',
          alt: 'Birth control effectiveness',
        },
        {
          text: '11 Non-hormonal birth control methods',
          href: '/cyclematters/non-hormonal-birth-control-methods',
          thumbnail: '/img/ab280/non-hormonal-birth-control-methods.jpg',
          alt: '11 Non-hormonal birth control methods',
        },
      ],
      button: { cta: 'Explore more', href: '/cyclematters/birth-control' },
    },
    {
      category: 'Plan Pregnancy',
      articles: [
        {
          text: 'Getting pregnant after birth control',
          href: '/cyclematters/when-to-stop-birth-control-before-trying-to-conceive',
          thumbnail: '/img/ab280/when-to-stop-birth-control-before-trying-to-conceive.jpg',
          alt: 'Getting pregnant after birth control',
        },
        {
          text: 'Prepare for pregnancy',
          href: '/cyclematters/prepare-your-body-for-pregnancy',
          thumbnail: '/img/ab280/prepare-your-body-for-pregnancy.jpg',
          alt: 'Prepare for pregnancy',
        },
        {
          text: 'Prenatal vitamins',
          href: '/cyclematters/when-to-start-taking-prenatal-vitamins',
          thumbnail: '/img/ab280/when-to-start-taking-prenatal-vitamins.jpg',
          alt: 'Prenatal vitamins',
        },
      ],
      button: { cta: 'Explore more', href: '/cyclematters/plan-pregnancy' },
    },
    {
      category: 'Postpartum',
      articles: [
        {
          text: 'Postpartum recovery timeline',
          href: '/cyclematters/postpartum-recovery-timeline',
          thumbnail: '/img/ab280/postpartum-recovery-timeline.jpg',
          alt: 'Postpartum recovery timeline',
        },
        {
          text: 'Postpartum hair loss',
          href: '/cyclematters/postpartum-hair-loss',
          thumbnail: '/img/ab280/postpartum-hair-loss.jpg',
          alt: 'Postpartum hair loss',
        },
        {
          text: 'Postpartum swelling',
          href: '/cyclematters/postpartum-swelling',
          thumbnail: '/img/ab280/postpartum-swelling.jpg',
          alt: 'Postpartum swelling',
        },
      ],
      button: { cta: 'Explore more', href: '/cyclematters/postpartum' },
    },
    {
      category: 'Period & Bleeding',
      articles: [
        {
          text: 'Irregular periods',
          href: '/cyclematters/irregular-periods',
          thumbnail: '/img/ab280/irregular-periods.jpg',
          alt: 'Irregular periods',
        },
        {
          text: 'Withdrawal bleeding',
          href: '/cyclematters/what-is-withdrawal-bleeding',
          thumbnail: '/img/ab280/what-is-withdrawal-bleeding.jpg',
          alt: 'Withdrawal bleeding',
        },
        {
          text: 'Ovulation bleeding',
          href: '/cyclematters/ovulation-bleeding',
          thumbnail: '/img/ab280/ovulation-bleeding.jpg',
          alt: 'Ovulation bleeding',
        },
      ],
      button: { cta: 'Explore more', href: '/cyclematters/period-and-bleeding' },
    },
    {
      category: 'Reproductive Health',
      articles: [
        {
          text: 'How to clean your vagina and vulva',
          href: '/cyclematters/how-to-clean-your-vagina',
          thumbnail: '/img/ab280/how-to-clean-your-vagina.jpg',
          alt: 'How to clean your vagina',
        },
        {
          text: 'How to do a breast self-exam',
          href: '/cyclematters/how-to-do-a-self-breast-exam',
          thumbnail: '/img/ab280/how-to-do-a-self-breast-exam.jpg',
          alt: 'Self breast exam',
        },
        {
          text: 'Labia: 9 types of shapes, colors, and sizes',
          href: '/cyclematters/what-is-labia',
          thumbnail: '/img/ab280/what-is-labia.jpg',
          alt: 'Labia types',
        },
      ],
      button: { cta: 'Explore more', href: '/cyclematters/reproductive-health' },
    },
    {
      category: 'Sex & Pleasure',
      articles: [
        {
          text: 'How to have sex for the first time',
          href: '/cyclematters/a-beginners-guide-to-sex',
          thumbnail: '/img/ab280/a-beginners-guide-to-sex.jpg',
          alt: 'Sex for the first time',
        },
        {
          text: 'What is libido?',
          href: '/cyclematters/what-is-libido',
          thumbnail: '/img/ab280/what-is-libido.jpg',
          alt: 'What is libido?',
        },
        {
          text: 'The ultimate guide to clitoral stimulation',
          href: '/cyclematters/clitoral-orgasm',
          thumbnail: '/img/ab280/clitoral-orgasm.jpg',
          alt: 'Clitoral stimulation',
        },
      ],
      button: { cta: 'Explore more', href: '/cyclematters/sex-and-pleasure' },
    },
  ],
  button: { cta: 'Read articles', href: '/cyclematters' },
}

export const whatIsIncluded: MenuDropdownProps = {
  label: "What's included",

  listItems: [
    {
      label: 'NC° Birth Control',
      subLabel: 'Prevent pregnancy naturally',
      href: '/is-natural-cycles-right-for-me',
      iconPath: '/icons/iconsAb280/prevent-emoji.svg',
      iconAlt: 'Prevent emoji',
    },
    {
      label: 'NC° Plan Pregnancy',
      subLabel: 'Pinpoint your peak fertility',
      href: '/plan-pregnancy',
      iconPath: '/icons/iconsAb280/plan-emoji.svg',
      iconAlt: 'Plan emoji',
    },
    {
      label: 'NC° Postpartum',
      subLabel: 'Support recovery after childbirth',
      href: '/postpartum',
      iconPath: '/icons/iconsAb280/post-partum-emoji.svg',
      iconAlt: 'Post partum emoji',
    },
  ],
}
