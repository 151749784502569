import { localTime } from '@naturalcycles/js-lib'
import { SupportedLocale } from '@src/cnst/translations.cnst'
import languageContext from '@src/context/language.context'
import { getSignupURL } from '@src/helpers/signupUrl'
import React from 'react'

export const useBFCM = (): UseBFCMResult => {
  const [now, setNow] = React.useState(localTime.now())
  const { locale } = React.useContext(languageContext)

  React.useEffect(() => {
    const interval = setInterval(() => {
      setNow(localTime.now())
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  const isBlackFriday = now.isBetween(
    localTime.parse('2024-11-26T00:00:00'),
    localTime.parse('2024-11-30T23:59:59'),
  )

  const isCyberMonday = now.isBetween(
    localTime.parse('2024-12-01T00:00:00'),
    localTime.parse('2024-12-03T23:59:59'),
  )

  const code = isBlackFriday ? '20BFCMB' : isCyberMonday ? '25BFCMB' : null

  const signupUrl = getSignupURL(`/en-US/secret?code=${code}`).toString()

  const copyEnUS: CopyEntry = {
    headingLarge: isBlackFriday
      ? '🖤 Black Friday: Get 20% off NC° + up to $100 off Oura Ring while supplies last 🖤'
      : 'Don’t miss our BEST offer this year! Cyber Monday: 25% off NC°',
    headingSmall: isBlackFriday
      ? `🖤 <a href=${signupUrl}>Black Friday: Get 20% off NC° + up to $100 off Oura Ring while supplies last</a> 🖤`
      : `Don’t miss our BEST offer this year! <a href=${signupUrl}>Cyber Monday: 25% off NC°</a>`,
    buttonText: 'Claim offer',
  }

  const copyEsUS: CopyEntry = {
    headingLarge: isBlackFriday
      ? '🖤 Black Friday: Consigue un 20% de descuento en NC° + hasta $100 de descuento en Oura Ring mientras duren las existencias 🖤'
      : '¡No te pierdas nuestra MEJOR oferta del año! Cyber Monday: 25% de descuento en NC°',
    headingSmall: isBlackFriday
      ? `🖤 <a href=${signupUrl}>Black Friday: Consigue un 20% de descuento en NC° + hasta $100 de descuento en Oura Ring mientras duren las existencias</a> 🖤`
      : `¡No te pierdas nuestra MEJOR oferta del año! <a href=${signupUrl}>Cyber Monday: 25% de descuento en NC°</a>`,
    buttonText: 'Aprovecha la oferta',
  }

  const copyPtBR: CopyEntry = {
    headingLarge: isBlackFriday
      ? '🖤 Black Friday: Ganhe 20% de desconto no NC° + e até $100 de desconto no Oura Ring enquanto durarem os estoques 🖤'
      : 'Cyber Monday! Não perca a MELHOR oferta deste ano 25% de desconto no NC°',
    headingSmall: isBlackFriday
      ? `🖤 <a href=${signupUrl}>Black Friday: Ganhe 20% de desconto no NC° + e até $100 de desconto no Oura Ring enquanto durarem os estoques</a> 🖤`
      : `Cyber Monday! Não perca a MELHOR oferta deste ano <a href=${signupUrl}>25% de desconto no NC°</a>`,
    buttonText: 'Aceitar oferta',
  }

  const copySvSE: CopyEntry = {
    headingLarge: isBlackFriday
      ? '🖤 Black Friday: Få 20% rabatt på NC° + upp till €100 rabatt på Oura Ring så långt lagret räcker 🖤'
      : 'Missa inte årets BÄSTA erbjudande! Cyber Monday: 25% rabatt på NC°',
    headingSmall: isBlackFriday
      ? `🖤 <a href=${signupUrl}>Black Friday: Få 20% rabatt på NC° + upp till €100 rabatt på Oura Ring så långt lagret räcker</a> 🖤`
      : `Missa inte årets BÄSTA erbjudande! <a href=${signupUrl}>Cyber Monday: 25% rabatt på NC°</a>`,
    buttonText: 'Gå till erbjudande',
  }

  const copyMap: Record<SupportedLocale, CopyEntry> = {
    [SupportedLocale.enUS]: copyEnUS,
    [SupportedLocale.enCA]: copyEnUS,
    [SupportedLocale.esUS]: copyEsUS,
    [SupportedLocale.ptBR]: copyPtBR,
    [SupportedLocale.svSE]: copySvSE,
  }

  return {
    isBlackFriday,
    isCyberMonday,
    headingLarge: copyMap[locale].headingLarge,
    headingSmall: copyMap[locale].headingSmall,
    buttonProps: {
      url: signupUrl,
      text: copyMap[locale].buttonText,
    },
    code,
  }
}

interface CopyEntry {
  headingLarge: string
  headingSmall: string
  buttonText: string
}

interface UseBFCMResult {
  isBlackFriday: boolean
  isCyberMonday: boolean
  headingLarge: string
  headingSmall: string
  buttonProps: {
    url: string
    text: string
  }
  code: string | null
}
