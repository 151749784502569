import { _objectAssign, _objectEntries, isClientSide } from '@naturalcycles/js-lib'
import { navigate } from 'gatsby'
import { getUtmQueryParams } from './queryParams'

export const shouldReceiveUtmTags = (url: string): boolean => {
  let shouldReceive = false
  const internalSubdomains = ['webshop.naturalcycles.com', 'signup.naturalcycles.com']
  try {
    shouldReceive = internalSubdomains.includes(new URL(url).host)
  } catch {}
  return shouldReceive
}

export function isExternalUrl(url: string): boolean {
  const hasInternalPrefix = ['/', '#'].some(prefix => url.startsWith(prefix))
  let hasInternalHost = false
  try {
    const internalHosts = [
      'naturalcycles.com',
      'www.naturalcycles.com',
      'webshop.naturalcycles.com',
      'signup.naturalcycles.com',
    ]
    if (isClientSide() || process.env['GATSBY_ENV'] === 'test') {
      internalHosts.push(globalThis.location.host)
    }
    hasInternalHost = internalHosts.includes(new URL(url).host)
  } catch {}
  return !hasInternalPrefix && !hasInternalHost
}

export function addUtmParamsToParams(params: URLSearchParams): URLSearchParams {
  const utmTags = _objectEntries(getUtmQueryParams()) as [string, string][]
  const newParams = new URLSearchParams(params)
  utmTags.forEach(([key, value]) => {
    newParams.set(key, value)
  })
  return newParams
}

export function addUtmParamsToLink(to: string): string {
  if (!shouldReceiveUtmTags(to)) {
    return to
  }
  const [baseUrl, search] = to.split('?')
  const params = new URLSearchParams(search)
  const newParams = addUtmParamsToParams(params)
  return `${baseUrl}${newParams.size ? `?${newParams.toString()}` : ''}`
}

/**
 * A wrapper around Gatsby's navigate function that adds UTM tags to the URL.
 * It also is able to handle external URLs, setting window.location.href instead of using Gatsby's navigate.
 *
 * Passing a string as the first argument will navigate to the URL.
 * Passing a number as the first argument will navigate to a relative position in the history stack.
 */
export function ncNavigate(to: string, options?: { state?: any; replace?: boolean }): void

/**
 * A wrapper around Gatsby's navigate function that adds UTM tags to the URL.
 * It also is able to handle external URLs, setting window.location.href instead of using Gatsby's navigate.
 *
 * Passing a string as the first argument will navigate to the URL.
 * Passing a number as the first argument will navigate to a relative position in the history stack.
 */
export function ncNavigate(to: number, options?: undefined): void

export function ncNavigate(
  to: string | number,
  options?: { state?: any; replace?: boolean },
): void {
  if (typeof to === 'number') {
    // Meaning relative navigation in history
    navigate(to)
    return
  }

  to = addUtmParamsToLink(to)

  const { state } = options || {}
  if (isExternalUrl(to)) {
    if (state) {
      _objectAssign(globalThis.history.state, state)
    }
    globalThis.location.href = to
    return
  }

  /**
   * todo: gatsby types for this fn are broken and I can't figure out why
   * The source code clearly shows this is the correct signature, so we cast it to any for now
   *
   * export interface NavigateFn {
   *  (to: string, options?: NavigateOptions<{}>): Promise<void>; // <-- Reach router types that are async, but gatsby voids them
   *  (to: number, options?: undefined): Promise<void>;
   * }
   *
   * export const navigate: (...args: Parameters<NavigateFn>) => void;
   *
   * With the logic here supporting it:
   * https://github.com/gatsbyjs/gatsby/blob/df69f04835410de7b73189d060bf4dc991768973/packages/gatsby/cache-dir/navigation.js#L56-L62
   */
  navigate(to as any, options as any)
}
